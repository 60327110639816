import { useMediaQuery } from '@mui/material'
import React from 'react'
import { maxWidth } from '../constants/MobilePixelWidth';
import NavBottom from './NavBottom'
import NavSide from './NavSide'

export default function Nav() {
    const isMobile = useMediaQuery(`(max-width:${maxWidth}px)`);

    return (
        <>
            {isMobile ? <NavBottom /> : <NavSide />}
        </>
    )
}