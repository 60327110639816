import ITeamSeason from '../../../dto/interfaces/ITeamSeason';
import TurnoverXLocation from '../../../components/teamsCharts/TurnoverXLocation';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import PointValueByTime from '../../../components/teamsCharts/PointValueByTime';


interface IProps {
    team: ITeamSeason
    teamList: ITeamSeason[]
}

interface IChartSelection {
    id: string,
    label: string,
    chart: any
}

export default function Charts({ team, teamList }: IProps) {
    const [selected, setSelected] = useState("no-selection");
    const label = "Select chart";
    const charts: IChartSelection[] = [
        { id: "no-selection", label: "Select a chart..", chart: <></> },
        { id: "TurnoverDistanceFromSideline", label: "Turnover % by distance from sideline", chart: <TurnoverXLocation team={team} /> },
        { id: "pointExpectedvalueByTime", label: "Expected point value by time", chart: <PointValueByTime team={team} teamList={teamList} /> }
    ]

    const handleSelect = (event: SelectChangeEvent) => {
        setSelected(event.target.value as string);
    };


    return (
        <div className='halfWidth'>
            <FormControl sx={{ width: '100%', marginBottom: '10px' }}>
                <InputLabel>{label}</InputLabel>
                <Select
                    value={selected}
                    onChange={handleSelect}
                    sx={{ width: '100%' }}
                    label={label}
                >
                    {
                        charts.map((chart) => {
                            return (
                                <MenuItem value={chart.id}>{chart.label}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            {selected && charts.find(x => x.id === selected)?.chart}
        </div>
    )
}