import axios from "axios"
import { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import ITeamSeason from "../dto/interfaces/ITeamSeason"
import { selectedSeasonId } from "../state/store/Atoms"
import { headerObject } from "../state/store/Selectors"
import api from "../api_urls"
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"




interface IProps {
    team: ITeamSeason;
    selectedTeams: number[];
    setSelectedTeams: (teams: number[]) => void;
    teamList2: ITeamSeason[];
}


export default function SelectMultipleTeams({ team, selectedTeams, setSelectedTeams, teamList2 }: IProps) {
    const header = useRecoilValue(headerObject);
    const seasonId = useRecoilValue(selectedSeasonId)
    const [teamList, setTeamList] = useState<ITeamSeason[]>([]);
    const label = "Select teams to display";

    useEffect(() => {
        axios.get<ITeamSeason[]>(`${api.ROOT}${api.TEAMS}?seasonId=${seasonId}`, header)
            .then(res => {
                var teamsResponse = res.data;
                teamsResponse.sort((a, b) => {
                    if (a.city < b.city) { return -1; }
                    if (a.city > b.city) { return 1; }
                    return 0;
                });
                setTeamList(teamsResponse);
            })
    }, [seasonId])

    const handleSelectTeams = (event: SelectChangeEvent<any>) => {
        const selected = teamList.filter(x => event.target.value.includes(x.id));
        setSelectedTeams(selected.map(x => x.id));
    };

    return (
        <FormControl sx={{ marginTop: '10px' }}>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple={true}
                value={selectedTeams}
                onChange={handleSelectTeams}
                label={label}
            >
                {teamList.map((team) => {
                    return (
                        <MenuItem
                            key={team.id}
                            value={team.id}
                        >
                            {`${team.city} ${team.team.name}`}
                        </MenuItem>
                    )
                })

                }

            </Select>
        </FormControl>
    )
}