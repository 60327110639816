import { useEffect } from 'react';
import '../css/base.css';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormGroup, MenuItem } from '@mui/material';
import ITeamSeason from '../dto/interfaces/ITeamSeason';
import api from '../api_urls';
import axios from 'axios';
import { headerObject } from '../state/store/Selectors';
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedSeasonId } from '../state/store/Atoms';
import { seasonIdMapping } from '../constants/SeasonIdMapping';
import ISeasonIdMapping from '../dto/interfaces/ISeasonIdMapping';
import IPlayer from '../dto/interfaces/IPlayer';

interface IProps {
    handleChange: (e: SelectChangeEvent) => void;
    selectedTeam: ITeamSeason;
    setTeamList: (teamList: ITeamSeason[]) => void;
    teamList: ITeamSeason[];
    playerSelect: boolean;
    handlePlayerChange?: (event: SelectChangeEvent) => void;
    player?: IPlayer;
    teamPlayers?: IPlayer[];
}

export default function SelectATeam({ handleChange, selectedTeam, setTeamList, teamList, playerSelect, handlePlayerChange, player, teamPlayers }: IProps) {
    const header = useRecoilValue(headerObject);
    const [seasonId, setSeasonId] = useRecoilState(selectedSeasonId)

    useEffect(() => {
        axios.get<ITeamSeason[]>(`${api.ROOT}${api.TEAMS}?seasonId=${seasonId}`, header)
            .then(res => {
                var teamsResponse = res.data;
                teamsResponse.sort((a, b) => {
                    if (a.city < b.city) { return -1; }
                    if (a.city > b.city) { return 1; }
                    return 0;
                });
                setTeamList(teamsResponse);
            })
    }, [seasonId])

    const changeSeasonId = (e: SelectChangeEvent) => {
        setSeasonId(e.target.value);
    }

    return (
        <FormGroup row={true}>
            <Select
                value={seasonId}
                onChange={changeSeasonId}
                sx={{ width: '100px', marginRight: '10px' }}
            >
                {seasonIdMapping.map((season: ISeasonIdMapping) => {
                    return (
                        <MenuItem key={season.id} value={season.id}>{season.year}</MenuItem>
                    )
                })}

            </Select>
            <Select
                value={selectedTeam?.team?.ext_team_id}
                defaultValue={"default"}
                onChange={handleChange}
                sx={{ flexGrow: 1 }}
            >
                <MenuItem key="default" value="default">Select a team..</MenuItem>
                {teamList.map((team) => {
                    return (<MenuItem key={team.team_id} value={team.team.ext_team_id}>{team.city} {team.team.name}</MenuItem>)
                })}
            </Select>
            {playerSelect &&
                <>
                    {selectedTeam &&
                        <Select
                            value={player?.ext_player_id}
                            onChange={handlePlayerChange}
                            sx={{ flexGrow: 2, marginLeft: "10px" }}
                        >
                            <MenuItem key={"default"} value={"default"}>Select a player.. </MenuItem>
                            {teamPlayers?.map((player) => {
                                return (<MenuItem key={player.ext_player_id} value={player.ext_player_id}>{player.first_name} {player.last_name}</MenuItem>)
                            })}
                        </Select>}
                </>
            }
        </FormGroup>
    );
}