import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Modal, TextField, Typography, useMediaQuery } from "@mui/material";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import { bugText } from "../state/store/Atoms";
import api from '../api_urls'
import { headerObject } from "../state/store/Selectors";
import IBug from '../dto/interfaces/IBug';
import { useState } from "react";
import { maxWidth } from "../constants/MobilePixelWidth";


interface IProps {
    open: boolean;
    setOpen: (state: boolean) => void;
}

export default function ReportABug({ open, setOpen }: IProps) {
    const isMobile = useMediaQuery(`(max-width:${maxWidth}px)`);
    const header = useRecoilValue(headerObject);
    const { user } = useAuth0();
    const [text, setText] = useRecoilState(bugText);
    const [thankYou, setThankYou] = useState(false);
    const modalWidth = isMobile ? '90%' : '50%';
    const inputCharacterMaximum = 1000;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newText = event.target.value;
        if (newText.length <= inputCharacterMaximum) {
            setText(newText);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setThankYou(false);
    }

    async function sendReport() {
        const bugReport = {
            reportedBy: user?.name,
            details: text
        } as IBug
        const res = await axios.post(`${api.ROOT}${api.REPORT_BUG}`, bugReport, header);
        setText("")
        setThankYou(true);
    }

    const overCharLimit = () => {
        return text.length === inputCharacterMaximum
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}>

                <Box
                    style={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white',
                        padding: '10px',
                        width: `${modalWidth}`,
                        justifyContent: 'flex-end',
                        display: 'flex',
                        flexWrap: 'wrap'
                    }}
                >
                    {!thankYou ?
                        <>
                            <Typography
                                sx={{ width: '100%', marginBottom: '15px' }}
                                align="center"
                                variant="h5">
                                Report a bug
                            </Typography>
                            <TextField
                                multiline
                                variant="outlined"
                                label="Please describe the issue"
                                rows={6}
                                value={text}
                                onChange={handleChange}
                                sx={{ width: '100%', marginBottom: "10px" }}
                                error={overCharLimit()}
                                helperText={overCharLimit() && `${inputCharacterMaximum} character limit (thanks for your thoroughness! Please send me an email or slack if you wish to include more detail!)`} />
                            <Typography sx={{ paddingTop: "7px", fontSize: '11px', fontStyle: "italic", marginRight: "10px", marginLeft: '0px' }}>Your name will be recorded with this response</Typography>
                            <Button variant="contained" sx={{ width: '15%' }} onClick={sendReport}>Submit</Button>
                        </> :
                        <>
                            <Typography sx={{ width: '100%', }} align="center">Bug reported. Thank you!</Typography>
                        </>}

                </Box>
            </Modal>
        </>
    );
}