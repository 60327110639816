import IXy from '../../dto/interfaces/IXy';
import { AgChartsReact } from 'ag-charts-react';
import { AgChartOptions } from 'ag-charts-community';
import { ITeamData } from './PointValueByTime';



interface IProps {
    buildOptions: (data: ITeamData[], title: string) => AgChartOptions;
    title: string;
    data: ITeamData[];
}

export default function PointValueSingleChart({ buildOptions, title, data }: IProps) {

    const options = buildOptions(data, title);

    return (
        <div className='charts'>
            <AgChartsReact
                options={options}
            />
        </div>
    )
}