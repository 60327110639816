import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEffect } from "react"
import { useRecoilValue } from "recoil";
import api from "../api_urls";
import Loading from "../components/Loading";
import { headerObject } from "../state/store/Selectors";

export default function RickRoll() {
    const { user } = useAuth0();
    const header = useRecoilValue(headerObject);

    useEffect(() => {
        const info = {
            name: user?.name,
            details: "Do not sell my data"
        }
        axios.post(`${api.ROOT}${api.OPT_OUT}`, info, header)
            .then(() => {
                window.location.href = "https://www.youtube.com/watch?v=a3Z7zEc7AXQ&ab_channel=RVE";
            })

    }, [header])

    return (
        <>
            <Loading />
        </>
    )
}