import { FormControl, Typography } from '@mui/material';
import React from 'react';

export default function UnauthorizedView() {

    return (
        <FormControl sx={{ display: "flex", flexDirection: "column", height: "100vh", alignItems: "center", justifyContent: 'center' }}>
            <Typography variant="h3" align='center' sx={{ marginBottom: "20px" }}>You are not authorized to view this page</Typography>
            <Typography variant="subtitle1" sx={{ marginLeft: '10px', marginRight: '10px', marginBottom: "20px" }}>You will see this error if you are attempting to access this page with a different email than we have on file (the one you used to fill out your preseason interest form)</Typography>
            <Typography variant="subtitle1">Please contact max to update your email</Typography>
        </FormControl>
    );
}