import { atom } from 'recoil';
import { recoilPersist } from "recoil-persist";
import IFolder from '../../dto/interfaces/vimeo/IFolder';
import IVideo from '../../dto/interfaces/vimeo/IVideo';

const { persistAtom } = recoilPersist();

export const accessToken = atom({
    key: 'accessToken',
    default: ""
})

export const defaultTeamName = atom({
    key: 'default',
    default: 'default'
})

export const selectedSeasonId = atom({
    key: 'selectedSeasonId',
    default: "15",
    effects_UNSTABLE: [persistAtom]
})

export const bugText = atom({
    key: 'bugText',
    default: ''
})

export const tree_recoil = atom({
    key: 'tree',
    default: [] as IFolder[],
    effects_UNSTABLE: [persistAtom]
})

export const videoList_recoil = atom({
    key: 'videoList',
    default: [] as IVideo[],
    effects_UNSTABLE: [persistAtom]
})
