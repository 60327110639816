import { Box, Button, FormControl, Typography } from '@mui/material';
import { IWelcomeProps } from './WelcomePage';

export default function Desktop({ testConnection, getColor }: IWelcomeProps) {

    return (
        <div id='welcome'>
            <FormControl sx={{ display: "flex", flexDirection: "column", height: "100vh", alignItems: "center" }}>
                <Typography variant="h3" >Welcome!</Typography>
                <Box sx={{ width: "50px", height: "50px", backgroundColor: getColor(), marginTop: '10px', marginBottom: '10px' }}></Box>
                <Button onClick={testConnection} >Test Connection</Button>
                <Typography variant="subtitle1" align='center'>Click this button to test the connection with the server</Typography>
                <Typography variant="subtitle1" align='center'>(It sleeps after a period of inactivity)</Typography>
                <Typography variant="subtitle1" align='center'>The box will turn green when it is ready</Typography>
                <Typography variant="subtitle1" align='center'>This can take up to 30ish seconds sometimes, please be patient</Typography>

            </FormControl>
        </div>
    );
}