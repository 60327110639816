import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { accessToken } from "../../state/store/Atoms";
import { useRecoilState } from "recoil";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { maxWidth } from "../../constants/MobilePixelWidth";
import ReportABug from "../ReportABug";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

interface IProps {
    menuOptionsFontColor?: string;
    listStyles?: any
}

const LogoutButton = ({ menuOptionsFontColor, listStyles }: IProps) => {
    const isMobile = useMediaQuery(`(max-width:${maxWidth}px)`);
    const { logout, getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useRecoilState(accessToken);
    const [openBug, setOpenBug] = useState(false);
    const navigate = useNavigate();
    const faqLink = "https://docs.google.com/document/d/17-5kcBLNV9uxJ4UI7j6HeAFvO26zoUQLaHvQip5ZiaM/edit?usp=sharing";

    useEffect(() => {
        getAccessTokenSilently()
            .then((jwt) => {
                setToken(jwt);
            })
    }, [])

    useEffect(() => {
        if (token !== "") {
            if (window.sessionStorage.getItem('redirect')) {
                const url = window.sessionStorage.getItem('redirect') || "";
                window.sessionStorage.removeItem('redirect')
                const path = url.replace(window.location.origin, "")
                navigate(path)
            }
        }
    }, [token])

    const handleLogout = () => {
        setToken("");
        logout({ returnTo: window.location.origin });
    }

    const handleOpenBug = () => {
        setOpenBug(true);
    }

    const openFaq = () => {
        window.open(faqLink, "_blank")
    }

    return (
        <>
            <ReportABug open={openBug} setOpen={setOpenBug} />
            {isMobile ?
                <Mobile
                    handleOpenBug={handleOpenBug}
                    fontColor={menuOptionsFontColor || ""}
                    listStyles={listStyles}
                    handleLogout={handleLogout}
                    openFaq={openFaq}
                /> :
                <Desktop
                    handleOpenBug={handleOpenBug}
                    fontColor={menuOptionsFontColor || ""}
                    listStyles={listStyles}
                    handleLogout={handleLogout}
                    openFaq={openFaq}
                />

            }
        </>
    );
};

export default LogoutButton;