import React, { useState } from 'react'
import "../../../css/players.css"

import ITeamSeason from '../../../dto/interfaces/ITeamSeason';
import FilterPasses, { PassType } from '../../../components/FilterPasses';
import IPlayer from '../../../dto/interfaces/IPlayer';
import Field from '../../../components/fields/passField';
import IFilterablePass from '../../../dto/interfaces/IFilterablePass';
import SummaryTablePasses from '../../../components/SummaryTablePasses';


interface IProps {
    team: ITeamSeason;
    player: IPlayer;
    type: PassType
}

export default function Passes({ team, player, type }: IProps) {
    const [displayPasses, setDisplayPasses] = useState<IFilterablePass[]>([]);
    const [display, setDisplay] = useState(false);
    const [activePassId, setActivePassId] = useState<number>(0);

    const updateDataDisplay = () => {
        setDisplay(true);
    }

    const hideData = () => {
        setDisplay(false);
    }

    return (
        <>
            <div className="passesLayout">
                <FilterPasses
                    updateDataDisplay={updateDataDisplay}
                    hideData={hideData}
                    setFilteredPasses={setDisplayPasses}
                    team={team}
                    player={player}
                    type={type}
                />
                <Field passes={displayPasses} display={display} setActivePassId={setActivePassId} activePassId={activePassId} />
                <div className='infoColumn'>
                    <SummaryTablePasses passes={displayPasses} activePassId={activePassId} />
                </div>
            </div>
        </>
    )
}