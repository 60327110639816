import CopyUrl from '../../components/CopyUrl';
import Vimeo from '@u-wave/react-vimeo';
import VideoCatalog from '../../components/VideoCatalog';
import IVideo from '../../dto/interfaces/vimeo/IVideo';
import { Typography } from '@mui/material';

interface IProps {
    video: IVideo;
    setVideo: (video: IVideo) => void;
    descriptionLines: (raw: string) => string[];
}

export default function Desktop({ video, setVideo, descriptionLines }: IProps) {

    return (
        <div className="videoBody">
            <VideoCatalog setVideo={setVideo} />
            <div>
                <CopyUrl />
                {video.uri && <Vimeo video={`${video.uri}`} width={"700"} height={"400"} />}
                {descriptionLines(video.description).map((line) => {
                    return (
                        <Typography variant="body1">{line}</Typography>
                    )
                })}
            </div>
        </div>
    );
}
