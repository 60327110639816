import LogoutIcon from '@mui/icons-material/Logout';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

interface IProps {
    handleOpenBug: () => void;
    fontColor: string;
    listStyles: any;
    handleLogout: () => void;
    openFaq: () => void;
}

export default function Auth0Logout({ handleOpenBug, fontColor, listStyles, handleLogout, openFaq }: IProps) {

    return (
        <>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                onClick={() => openFaq()}
                sx={{ paddingRight: '0px', marginRight: '10px' }}>
                <HelpIcon />
            </IconButton>

            <IconButton
                size="large"
                edge="start"
                color="inherit"
                onClick={() => handleOpenBug()}
                sx={{ paddingRight: '0px', marginRight: '10px' }}
            >
                <ReportProblemIcon />
            </IconButton >

            <IconButton
                size="large"
                edge="start"
                color="inherit"
                onClick={() => handleLogout()}
                sx={{ paddingRight: '0px' }}
            >
                <LogoutIcon />
            </IconButton >
        </>
    )
}