import { Button } from "@mui/material";
import { useState } from "react";

export default function CopyUrl() {
    const [copied, setCopied] = useState(false);
    const copyUrl = "Copy Url"
    const copiedUrl = "Url copied!"

    const copyToClipboard = () => {
        navigator.clipboard.writeText(window.location.href)
        setCopied(true);
    }

    return (
        <Button
            onClick={copyToClipboard}
        >
            {copied ? copiedUrl : copyUrl}
        </Button>
    )
}