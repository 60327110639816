import { selector } from 'recoil';
import { accessToken } from './Atoms';
import jwt from 'jwt-decode'
import IJwtToken from '../../dto/interfaces/IJwtToken';
import auth0Roles from '../../constants/Auth0Roles'

export const headerObject = selector({
    key: 'headerObject',
    get: ({ get }) => {
        const token = get(accessToken);
        return {
            headers: {
                "Authorization": "Bearer " + token
            }
        }
    }
})

export const isAuthorized = selector({
    key: 'isAuthorized',
    get: ({ get }) => {
        const jwtToken = get(accessToken);
        const token: IJwtToken = jwtToken ? jwt<IJwtToken>(jwtToken) : {} as IJwtToken;
        const roles = token.permissions;
        return roles?.includes(auth0Roles.user);
    }
})

export const isAdmin = selector({
    key: 'isAdmin',
    get: ({ get }) => {
        const jwtToken = get(accessToken);
        const token: IJwtToken = jwtToken ? jwt<IJwtToken>(jwtToken) : {} as IJwtToken;
        const roles = token.permissions;
        return roles?.includes(auth0Roles.admin);
    }
})