import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import '../css/base.css';
import { Box, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Auth0Login from './Auth0Login';
import Auth0Logout from './Auth0Logout/Auth0Logout';
import { useAuth0 } from '@auth0/auth0-react';
import { secondary_main } from '../theme/theme';
import { ROUTES, ROUTES_ADMIN, IRouteInfo } from '../constants/Routes';
import { useRecoilValue } from 'recoil';
import { isAdmin } from '../state/store/Selectors';

// Hacky as heck way to determine which is selected to start
function determineStartingListItem() {
    const url = window.location.href;
    if (url.includes("games")) {
        return ("Games");
    }
    if (url.includes("players")) {
        return ("Players");
    }
    if (url.includes("teams")) {
        return ("Teams");
    }
}

export default function SideNav() {
    const navigate = useNavigate();
    const admin = useRecoilValue(isAdmin)
    const { isAuthenticated } = useAuth0();
    const menuOptionsFontColor: string = 'white'
    const [selectedItem, setSelectedItem] = useState(determineStartingListItem());

    function handleClick(link: string, name: string) {
        navigate(link);
        setSelectedItem(name);
    }

    function navigateToHome() {
        setSelectedItem("");
        navigate("/");
    }

    function mapRoutes(routes: IRouteInfo[]) {
        return routes.map((option: IRouteInfo) => {
            const isSelected = option.name === selectedItem;
            return ((
                <Box>
                    <ListItemButton
                        key={option.name}
                        onClick={() => handleClick(option.path, option.name)}
                        sx={{
                            color: menuOptionsFontColor,
                            fontStyle: isSelected ? 'italic' : 'initial',
                            backgroundColor: isSelected ? secondary_main : '',
                        }} >
                        <ListItemIcon sx={{ color: menuOptionsFontColor }}>
                            {option.icon}
                        </ListItemIcon>
                        <ListItemText primary={option.name} />
                    </ListItemButton>
                </Box>
            ))
        })
    }

    const listStyles = { "& :hover": { backgroundColor: secondary_main } }

    return (
        <div className="drawerWidth" >
            <Drawer
                variant="permanent"
                anchor="left"
            >
                <Toolbar sx={{ paddingLeft: '5px', paddingRight: '5px' }} disableGutters onClick={navigateToHome} style={{ cursor: 'pointer' }}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/3/32/Minnesota_Wind_Chill_Logo.jpg" height="50" alt="wind chill logo"></img>
                    <Box sx={{ color: 'white', fontWeight: 'bold', fontSize: "25px", paddingLeft: '5px' }}>Wind Chill</Box>
                </Toolbar>
                {isAuthenticated ?
                    (
                        <div className="flexSpaceBetween">
                            <List sx={listStyles}>
                                {mapRoutes(ROUTES)}
                                {admin && mapRoutes(ROUTES_ADMIN)}
                            </List>
                            <Auth0Logout menuOptionsFontColor={menuOptionsFontColor} listStyles={listStyles} />
                        </div>
                    ) :
                    <Auth0Login />
                }

            </Drawer>
        </div >
    );
}