import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Checkbox, FormControlLabel, FormGroup, SelectChangeEvent, Switch } from '@mui/material';
import CategoricalSelector from './CategoricalSelector'
import IFilterablePass from '../dto/interfaces/IFilterablePass';
import ITeamSeason from '../dto/interfaces/ITeamSeason';
import axios from 'axios';
import api from '../api_urls'
import IPlayer from '../dto/interfaces/IPlayer';
import { useRecoilValue } from 'recoil';
import { headerObject } from '../state/store/Selectors';
import { yMax, multiplier, fieldWidth } from '../state/fieldValues';
import FieldAreaSlider from './FieldAreaSlider';

interface IProps {
    updateDataDisplay: () => void;
    hideData: () => void;
    setFilteredPasses: (passes: IFilterablePass[]) => void;
    team: ITeamSeason;
    player: IPlayer;
    type: PassType
}

export enum PassType {
    thrower,
    target
}

export default function FilterPasses({ updateDataDisplay, hideData, setFilteredPasses, team, player, type }: IProps) {
    const header = useRecoilValue(headerObject);
    const [allPasses, setAllPasses] = useState<IFilterablePass[]>([]);
    const [availableGames, setAvailableGames] = useState<string[]>([]);
    const [selectedGames, setSelectedGames] = useState<string[]>([]);
    const marginSize = 10;
    const formControlLabel = { marginRight: `${marginSize}px` }

    const [xLow, setXLow] = useState<number>(0);
    const [yLow, setYLow] = useState<number>(-yMax / multiplier);
    const [xHigh, setXHigh] = useState<number>(fieldWidth / multiplier);
    const [yHigh, setYHigh] = useState<number>(yMax / multiplier);
    const [xFilters, setXFilters] = useState(false);
    const [yFilters, setYFilters] = useState(false);
    const [q1, setQ1] = useState(true);
    const [q2, setQ2] = useState(true);
    const [q3, setQ3] = useState(true);
    const [q4, setQ4] = useState(true);
    const [q5, setQ5] = useState(true);
    const [q6, setQ6] = useState(true);
    const [goal, setGoal] = useState(true);
    const [catchInPlay, setCatchInPlay] = useState(true);
    const [drop, setDrop] = useState(true);
    const [throwingError, setThrowingError] = useState(true);

    useEffect(() => {
        if (team.city !== 'default') {
            axios.get<IFilterablePass[]>(`${api.ROOT}${api.TEAMS}/${team.id}${api.PLAYERS}/${player.ext_player_id}${type == PassType.thrower ? api.ALL_THROWS : api.ALL_TARGETS}`, header)
                .then((res) => {
                    setAllPasses(res.data);
                })
            axios.get<string[]>(`${api.ROOT}${api.TEAMS}/${team.id}${api.PLAYERS}/${player.ext_player_id}${api.GAMES_PLAYED}`, header)
                .then((res) => {
                    setAvailableGames(res.data);
                    setSelectedGames(res.data);
                })
        }
    }, [player])

    const renderSelectedGameValue = () => {
        const nGames = selectedGames.length;
        return nGames < 2 ? selectedGames : `${selectedGames.length} games selected`;
    }

    const selectAllGames = () => {
        setSelectedGames(availableGames);
    }

    const selectNoGames = () => {
        setSelectedGames([]);
    }

    const handleXFilter = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setXFilters(checked);
    }

    const handleYFilter = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setYFilters(checked);
    }

    const setBackward = () => {
        const value = yMax / multiplier;
        setYLow(-value);
        setYHigh(0);
    }

    const setAll = () => {
        const value = yMax / multiplier;
        setYLow(-value);
        setYHigh(value);
    }

    const setForward = () => {
        const value = yMax / multiplier;
        setYLow(0);
        setYHigh(value);
    }

    const handleQuarter = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.id) {
            case "q1":
                setQ1(event.target.checked);
                break;
            case "q2":
                setQ2(event.target.checked);
                break;
            case "q3":
                setQ3(event.target.checked);
                break;
            case "q4":
                setQ4(event.target.checked);
                break;
            case "q5":
                setQ5(event.target.checked);
                break;
            case "q6":
                setQ6(event.target.checked);
                break;
        }
    }

    const handleResult = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.id) {
            case "goal":
                setGoal(event.target.checked);
                break;
            case "catch":
                setCatchInPlay(event.target.checked);
                break;
            case "drop":
                setDrop(event.target.checked);
                break;
            case "throwingError":
                setThrowingError(event.target.checked);
                break;
        }
    }

    const handleChangeSelectedGames = (event: SelectChangeEvent<typeof selectedGames>) => {
        const {
            target: { value },
        } = event;
        setSelectedGames(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const doFilter = () => {
        var filtered = allPasses.filter(x => selectedGames.includes(x.gameId))
        filtered = yFilters ? filtered.filter(x => (x.yardsY <= yHigh) && (x.yardsY >= yLow)) : filtered;
        filtered = xFilters ? filtered.filter(x => (Math.abs(x.yardsX) <= xHigh) && (Math.abs(x.yardsX) >= xLow)) : filtered;

        filtered = q1 ? filtered : filtered.filter(x => x.quarter !== 1);
        filtered = q2 ? filtered : filtered.filter(x => x.quarter !== 2);
        filtered = q3 ? filtered : filtered.filter(x => x.quarter !== 3);
        filtered = q4 ? filtered : filtered.filter(x => x.quarter !== 4);
        filtered = q5 ? filtered : filtered.filter(x => x.quarter !== 5);
        filtered = q6 ? filtered : filtered.filter(x => x.quarter !== 6);

        filtered = goal ? filtered : filtered.filter(x => x.result !== 'GOAL');
        filtered = catchInPlay ? filtered : filtered.filter(x => x.result !== 'CATCH');
        filtered = throwingError ? filtered : filtered.filter(x => x.result !== 'THROWING_ERROR' && x.result !== 'CALLAHAN');
        filtered = drop ? filtered : filtered.filter(x => x.result !== 'DROP');

        setFilteredPasses(filtered);
        updateDataDisplay();
    }

    return (
        <div className='fieldFilters'>

            {/* Games Filter */}
            <CategoricalSelector
                label="Games"
                selected={selectedGames}
                available={availableGames}
                selectNone={selectNoGames}
                selectAll={selectAllGames}
                renderFcn={renderSelectedGameValue}
                changeFcn={handleChangeSelectedGames}
            />

            {/* Throw distance filter */}
            <FormGroup className="filter" sx={{ marginBottom: "0px" }}>
                <FormControlLabel control={<Switch checked={yFilters} onChange={handleYFilter} />} label="Enable throwing yards filter" />
                {yFilters && <ButtonGroup sx={{ paddingTop: "10px", width: "100%" }}>
                    <Button onClick={setBackward} sx={{ width: "100%" }}>
                        Backward
                    </Button>
                    <Button onClick={setAll} sx={{ width: "100%" }}>
                        All
                    </Button>
                    <Button onClick={setForward} sx={{ width: "100%" }}>
                        Forward
                    </Button>
                </ButtonGroup>}
                {yFilters && <FieldAreaSlider low={yLow} high={yHigh} setLow={setYLow} setHigh={setYHigh} min={-yMax / multiplier} max={yMax / multiplier} track={"normal"} />}
                <FormControlLabel control={<Switch checked={xFilters} onChange={handleXFilter} />} label="Enable horizontal yards filter" />
                {xFilters && <FieldAreaSlider low={xLow} high={xHigh} setLow={setXLow} setHigh={setXHigh} min={0} max={fieldWidth / multiplier} track={"normal"} />}
            </FormGroup>

            {/* Quarter Filter */}
            <FormGroup className='filter' row={true} >
                <FormControlLabel sx={formControlLabel} control={<Checkbox id="q1" checked={q1} onChange={handleQuarter} disableRipple />} label="Q1" />
                <FormControlLabel sx={formControlLabel} control={<Checkbox id="q2" checked={q2} onChange={handleQuarter} disableRipple />} label="Q2" />
                <FormControlLabel sx={formControlLabel} control={<Checkbox id="q3" checked={q3} onChange={handleQuarter} disableRipple />} label="Q3" />
                <FormControlLabel sx={formControlLabel} control={<Checkbox id="q4" checked={q4} onChange={handleQuarter} disableRipple />} label="Q4" />
                <FormControlLabel sx={formControlLabel} control={<Checkbox id="q5" checked={q5} onChange={handleQuarter} disableRipple />} label="OT" />
                <FormControlLabel sx={formControlLabel} control={<Checkbox id="q6" checked={q6} onChange={handleQuarter} disableRipple />} label="2OT" />
            </FormGroup>

            {/* Pass Result Filter */}
            <FormGroup className='filter' row={true} >
                <FormControlLabel sx={formControlLabel} control={<Checkbox id="goal" checked={goal} onChange={handleResult} disableRipple />} label="Goal" />
                <FormControlLabel sx={formControlLabel} control={<Checkbox id="catch" checked={catchInPlay} onChange={handleResult} disableRipple />} label="Catch" />
                <FormControlLabel sx={formControlLabel} control={<Checkbox id="drop" checked={drop} onChange={handleResult} disableRipple />} label="Drop" />
                <FormControlLabel sx={formControlLabel} control={<Checkbox id="throwingError" checked={throwingError} onChange={handleResult} disableRipple />} label="Throwaway" />
            </FormGroup>

            {/* Buttons */}
            <ButtonGroup sx={{ paddingTop: "10px", width: "100%" }}>
                <Button onClick={doFilter} sx={{ width: '50%' }}>
                    Show data
                </Button>
                <Button onClick={hideData} sx={{ width: '50%' }} >
                    Reset
                </Button>
            </ButtonGroup>

        </div>
    )
}