import { useEffect, useState } from 'react'
import { Collapse, LinearProgress, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Paper, useMediaQuery } from '@mui/material';
import axios from 'axios';
import FolderIcon from '@mui/icons-material/Folder';
import IFolder from '../dto/interfaces/vimeo/IFolder';
import IVideo from '../dto/interfaces/vimeo/IVideo';
import TheatersIcon from '@mui/icons-material/Theaters';
import { maxWidth } from '../constants/MobilePixelWidth'
import api from "../api_urls";
import { headerObject } from '../state/store/Selectors';
import { useRecoilState, useRecoilValue } from 'recoil';
import { tree_recoil, videoList_recoil } from '../state/store/Atoms';


interface IProps {
    closeModal?: () => void;
    setVideo: (video: IVideo) => void;
}

export default function VideoCatalog({ closeModal, setVideo }: IProps) {
    const isMobile = useMediaQuery(`(max-width:${maxWidth}px)`);
    const header = useRecoilValue(headerObject);
    const [isLoaded, setIsLoaded] = useState(false);
    const listIndent = 2;
    const [openList, setOpenList] = useState<string[]>([]);
    const [videoList, setVideoList] = useRecoilState<IVideo[]>(videoList_recoil);
    const [tree, setTree] = useRecoilState<IFolder[]>(tree_recoil)

    useEffect(() => {
        axios.get<IFolder[]>(`${api.ROOT}${api.EXTERNAL}${api.VIMEO}${api.FOLDERS}`, header)
            .then((res) => {
                setTree(res.data);
            })
    }, [])

    const getIdFromClick = (e: any): string => {
        const id1 = e.target.offsetParent.id || "";
        const id2 = e.target.id || "";
        return id1 === "" ? id2 : id1;
    }

    useEffect(() => {
        var getVideosUrl = `${api.ROOT}${api.EXTERNAL}${api.VIMEO}${api.VIDEOS}`
        axios.get<IVideo[]>(`${getVideosUrl}`, header)
            .then((res) => {
                setVideoList(res.data);
                setIsLoaded(true);
            })
    }, [])

    // this is hacky
    // id can be in 2 different spots depending on what part of button is clicked
    // algorithm: check both, use whichever spot isn't undefined.
    // also getting 2 different event types I presume?
    // can't figure out what type e actually should be
    const handleOpenOrClose = (e: any) => {
        const id = getIdFromClick(e);
        if (isOpen(id, 1)) {
            const closeFolder = openList.filter(x => x !== id);
            setOpenList(closeFolder);
        } else {
            setOpenList(openList => [...openList, id]);
        }
    }

    const isOpen = (uri: string, y: number): boolean => {
        const ifOpen = openList.find(x => x === uri);
        return ifOpen === uri ? true : false;
    }

    const setVideoInParent = (e: any) => {
        const id1 = e.target.offsetParent.id;
        const id2 = e.target.id;
        const id = id1 === "" ? id2 : id1;
        const video = videoList.find(x => x.uri === id);

        if (video) {
            setVideo(video)
            window.history.pushState({}, "", `/videos/${video.id}`)
        }
    }

    const displayFolder = (folder: IFolder, level: number) => {
        const foldersInFolder = tree.filter(x => x.parentUri === folder.uri)
        const videosInFolder = videoList.filter(x => x.parentUri === folder.uri && !x.hideFromWebsite);

        return (
            <>
                <ListItemButton key={`${folder.uri}-folder`} id={`${folder.uri}`} onClick={handleOpenOrClose} sx={{ pl: level * listIndent }}>
                    <ListItemIcon >
                        <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary={`${folder.name}`} />
                </ListItemButton>
                <Collapse
                    key={`${folder.uri}-collapse`}
                    in={isOpen(folder.uri, 2)}>
                    {foldersInFolder.map((y) => {
                        return (
                            displayFolder(y, 1 + level)
                        )
                    })}
                    {
                        videosInFolder.length > 0 && videosInFolder.map((video) => {
                            return (
                                <ListItemButton key={`${video.uri}-video`} id={`${video.uri}`} onClick={setVideoInParent} sx={{ pl: listIndent + (level * listIndent) }}>
                                    <ListItemIcon >
                                        <TheatersIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`${video.name}`} />
                                </ListItemButton>
                            )
                        })
                    }
                    {
                        videosInFolder.length === 0 && foldersInFolder.length === 0 &&
                        <>
                            <ListItemButton sx={{ pl: listIndent + (level * listIndent), fontStyle: "italic" }}>
                                <ListItemText primary={"No public videos"} />
                            </ListItemButton>
                        </>
                    }
                </Collapse>

            </>
        )
    }

    const getHeadingText = () => {
        var name = isMobile ? "Close" : "Video Library"
        return isLoaded ? name : `${name} (refreshing)`
    }

    return (
        <Paper elevation={0} style={{ width: 400, maxHeight: "calc(100vh - 20px)", overflow: 'auto', marginRight: "10px", marginLeft: "20px" }}>

            <List
                sx={{ paddingLeft: "5px", }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader" onClick={closeModal}>
                        {getHeadingText()}
                    </ListSubheader>
                }
            >
                {isLoaded ? null : <LinearProgress />}
                {tree.map((item) => {
                    if (!item.parentUri) {
                        return displayFolder(item, 0);
                    }
                })}
            </List>

        </Paper>
    )
}