import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Field from '../../../components/fields/possessionField';
import api from '../../../api_urls';
import ITeamSeason from '../../../dto/interfaces/ITeamSeason';
import IFilterablePossession from '../../../dto/interfaces/IFilterablePossession';
import { useRecoilValue } from 'recoil';
import { headerObject } from '../../../state/store/Selectors';
import IFilterablePass from '../../../dto/interfaces/IFilterablePass';
import { Box, Button, ButtonGroup, Checkbox, FormControlLabel, FormGroup, Modal, SelectChangeEvent, Switch, TextField, Typography } from '@mui/material';
import { xMin, xMax, yMax, yMin, multiplier } from '../../../state/fieldValues';
import { alphabeticalSort } from '../../../utilities/Sorters';
import FieldAreaSlider from '../../../components/FieldAreaSlider';
import CategoricalSelector from '../../../components/CategoricalSelector';
import DoubleIntPicker from '../../../components/DoubleIntPicker';
import GameTimeInput from '../../../components/GameTimeInput';
import SummaryTable from '../../../components/SummaryTable';

interface IProps {
    team: ITeamSeason;
}

const marginSize = 10;
const formControlLabel = { marginRight: `${marginSize}px` }

export default function PossessionViewer({ team }: IProps) {
    const [allPossessions, setAllPossessions] = useState<IFilterablePossession[]>([]);
    const [selectedPossessions, setSelectedPossessions] = useState<IFilterablePossession[]>([]);
    const [passes, setPasses] = useState<{ [key: string]: IFilterablePass[] }>({});
    const [games, setGames] = useState<string[]>([]);
    const [selectedGames, setSelectedGames] = useState<string[]>([]);
    const [xLow, setXLow] = useState<number>(xMin / multiplier);
    const [yLow, setYLow] = useState<number>(yMin / multiplier);
    const [xHigh, setXHigh] = useState<number>(xMax / multiplier);
    const [yHigh, setYHigh] = useState<number>(yMax / multiplier);
    const [display, setDisplay] = useState(false);
    const header = useRecoilValue(headerObject);
    const [xFilters, setXFilters] = useState(false);
    const [yFilters, setYFilters] = useState(false);
    const [xInvert, setXInvert] = useState(false);
    const [yInvert, setYInvert] = useState(false);
    const [pointPossessionNumbers, setPointPossessionNumber] = useState<string[]>([]);
    const [selectedPointPossessionNumbers, setSelectedPointPossessionNumbers] = useState<string[]>([]);
    const [possessionFilters, setPossessionFilters] = useState(false);
    const [lastPosOfQ, setLastPostOfQ] = useState(false);
    const [q1, setQ1] = useState(true);
    const [q2, setQ2] = useState(true);
    const [q3, setQ3] = useState(true);
    const [q4, setQ4] = useState(true);
    const [q5, setQ5] = useState(true);
    const [q6, setQ6] = useState(true);
    const [oPoints, setOPoints] = useState(true);
    const [dPoints, setDPoints] = useState(true);
    const [lastPointOfQ, setLastPointOfQ] = useState(false);
    const [marginMin, setMarginMin] = useState<number>(-10);
    const [marginMax, setMarginMax] = useState<number>(10);
    const [marginLow, setMarginLow] = useState<number | string>(-10);
    const [marginHigh, setMarginHigh] = useState<number | string>(10);
    const [minutes, setMinutes] = useState<number>(12);
    const [seconds, setSeconds] = useState<number>(0);
    const [activePossessionId, setActivePossessionId] = useState<number>(0);
    const [activePassId, setActivePassId] = useState<number>(0);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    useEffect(() => {
        if (team.city !== 'default') {
            axios.get<IFilterablePossession[]>(`${api.ROOT}${api.TEAMS}/${team.team.ext_team_id}${api.POSSESSIONS}`, header)
                .then((res) => {
                    setAllPossessions(res.data);
                    const gameIds = alphabeticalSort([...new Set(res.data.map(item => item.gameId))]);
                    setGames(gameIds);
                    setSelectedGames(gameIds);
                    setMargins(res.data);
                })
        }
    }, [team])

    useEffect(() => {
        // filters for available possession numbers
        // then selects all possession numbers as active every time a game changes
        // might be annoying but better than the alternative bc there is no way you leave out possessions accidently
        // TODO: Add a "Select First" button to grab only first possession of each point quickly
        const possessions = selectedGames.length === 0 ? allPossessions : allPossessions.filter(x => selectedGames.includes(x.gameId));
        const pointPosNumbersNumeric = [...new Set(possessions.map(pos => pos.pointPossessionNumber))].sort(function (a, b) { return a - b });
        const pointPosNumbersStrings = [...new Set(pointPosNumbersNumeric.map(pos => pos.toString()))];
        setPointPossessionNumber(pointPosNumbersStrings);
        setSelectedPointPossessionNumbers(pointPosNumbersStrings);
    }, [selectedGames])

    useEffect(() => {
        const possessionIds = selectedPossessions.map(pos => pos.possessionId);
        axios.post<{ [key: string]: IFilterablePass[] }>(`${api.ROOT}${api.PASSES_FROM_POSSESSIONS}`, { "ids": possessionIds }, header)
            .then((res) => {
                setPasses(res.data);
            })
    }, [selectedPossessions])

    const handleChangeSelectedGames = (event: SelectChangeEvent<typeof selectedGames>) => {
        const {
            target: { value },
        } = event;
        setSelectedGames(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangePointPossessionNumbers = (event: SelectChangeEvent<typeof selectedGames>) => {
        const {
            target: { value },
        } = event;
        setSelectedPointPossessionNumbers(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const selectAllGames = () => {
        setSelectedGames(games);
    }

    const selectNoGames = () => {
        setSelectedGames([]);
    }

    const selectAllPosNumbers = () => {
        setSelectedPointPossessionNumbers(pointPossessionNumbers);
    }

    const selectNoPosNumbers = () => {
        setSelectedPointPossessionNumbers([]);
    }

    const renderSelectedGameValue = () => {
        const nGames = selectedGames.length;
        return nGames < 2 ? selectedGames : `${selectedGames.length} games selected`;
    }

    const renderSelectedPointPossessionValue = () => {
        if (pointPossessionNumbers === selectedPointPossessionNumbers) {
            return "All possession numbers"
        } else {
            return selectedPointPossessionNumbers.toString();
        }
    }

    const handleXFilter = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setXFilters(checked);
    }

    const handleYFilter = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setYFilters(checked);
    }

    const handleXInvert = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setXInvert(checked);
    }

    const handleYInvert = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setYInvert(checked);
    }

    const handlePossessionFilter = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setPossessionFilters(checked);
    }

    const handleLastPosOfQ = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastPostOfQ(event.target.checked);
    }

    const handleQuarter = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.id) {
            case "q1":
                setQ1(event.target.checked);
                break;
            case "q2":
                setQ2(event.target.checked);
                break;
            case "q3":
                setQ3(event.target.checked);
                break;
            case "q4":
                setQ4(event.target.checked);
                break;
            case "q5":
                setQ5(event.target.checked);
                break;
            case "q6":
                setQ6(event.target.checked);
                break;
        }
    }

    const handleOPoints = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOPoints(event.target.checked);
    }

    const handleDPoints = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDPoints(event.target.checked);
    }

    const handleLastPointOfQ = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastPointOfQ(event.target.checked);
    }

    const handleMarginLow = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            setMarginLow('');
        } else {
            setMarginLow(Number(event.target.value));
        }
    };

    const handleBlurMarginLow = () => {
        if (marginLow === '') {
            setMarginLow(0);
        } else if (marginLow < marginMin) {
            setMarginLow(marginMin);
        } else if (marginLow > marginMax) {
            setMarginLow(marginMax)
        }

        if (marginLow > marginHigh) {
            swapMargins();
        }
    }

    const handleMarginHigh = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            setMarginHigh('');
        } else {
            setMarginHigh(Number(event.target.value));
        }
    };

    const handleBlurMarginHigh = () => {
        if (marginHigh === '') {
            setMarginHigh(0);
        } else if (marginHigh < marginMin) {
            setMarginHigh(marginMin);
        } else if (marginHigh > marginMax) {
            setMarginHigh(marginMax)
        }

        if (marginLow > marginHigh) {
            swapMargins();
        }
    }

    const swapMargins = () => {
        setMarginHigh(marginLow);
        setMarginLow(marginHigh);
    }

    const setMargins = (possessions: IFilterablePossession[]) => {
        const minimum = possessions.reduce((prev, curr) => prev.margin < curr.margin ? prev : curr).margin;
        const maximum = possessions.reduce((prev, curr) => prev.margin > curr.margin ? prev : curr).margin;
        setMarginLow(minimum);
        setMarginMin(minimum);
        setMarginHigh(maximum);
        setMarginMax(maximum);
    }

    const handleMinutes = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMinutes(Number(event.target.value));
    };

    const handleSeconds = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSeconds(Number(event.target.value));
    };

    const handleMinutesBlur = () => {
        if (minutes > 12) {
            setMinutes(12);
        } else if (minutes < 0) {
            setMinutes(0);
        }
    }

    const handleSecondsBlur = () => {
        if (seconds > 719) {
            setMinutes(12);
            setSeconds(0);
        } else if (seconds > 59) {
            const mins = Math.floor(seconds / 60);
            const secs = seconds % 60;
            setMinutes(mins);
            setSeconds(secs);
        } else if (minutes === 12) {
            setSeconds(0);
        }
        if (seconds < 0) {
            setSeconds(0);
        }
    }

    const updateDataDisplay = () => {
        var filtered = allPossessions.filter(x => selectedGames.includes(x.gameId))
        filtered = xFilters && xInvert ? filtered.filter(x => (x.startX >= xHigh) || (x.startX <= xLow)) : filtered;
        filtered = xFilters && !xInvert ? filtered.filter(x => (x.startX <= xHigh) && (x.startX >= xLow)) : filtered;
        filtered = yFilters && yInvert ? filtered.filter(x => (x.startY >= yHigh) || (x.startY <= yLow)) : filtered;
        filtered = yFilters && !yInvert ? filtered.filter(x => (x.startY <= yHigh) && (x.startY >= yLow)) : filtered;
        if (possessionFilters) {
            filtered = filtered.filter(x => selectedPointPossessionNumbers.includes(x.pointPossessionNumber.toString()));
            filtered = lastPosOfQ ? filtered.filter(x => x.lastPossessionOfQuarter === true) : filtered;
        }
        filtered = q1 ? filtered : filtered.filter(x => x.quarter !== 1);
        filtered = q2 ? filtered : filtered.filter(x => x.quarter !== 2);
        filtered = q3 ? filtered : filtered.filter(x => x.quarter !== 3);
        filtered = q4 ? filtered : filtered.filter(x => x.quarter !== 4);
        filtered = q5 ? filtered : filtered.filter(x => x.quarter !== 5);
        filtered = q6 ? filtered : filtered.filter(x => x.quarter !== 6);
        filtered = oPoints ? filtered : filtered.filter(x => x.oSituation !== team?.id);
        filtered = dPoints ? filtered : filtered.filter(x => x.dSituation !== team?.id);
        filtered = lastPointOfQ ? filtered.filter(x => x.lastPointOfQuarter) : filtered;
        filtered = filtered.filter(x => x.margin <= marginHigh);
        filtered = filtered.filter(x => x.margin >= marginLow);
        const secondsLeft = 60 * minutes + seconds === 720 ? 1200 : 60 * minutes + seconds;
        filtered = filtered.filter(x => x.startTime <= secondsLeft);
        setSelectedPossessions(filtered);
        setDisplay(true)
    }

    const hideData = () => {
        setDisplay(false);
        setActivePassId(0);
        setActivePossessionId(0);
    }

    const getPossessionPasses = (id: number): IFilterablePass[] => {
        const locatePass = passes[id];
        if (locatePass) {
            return locatePass;
        }
        return [];
    }

    const getPossession = (id: number): IFilterablePossession => {
        const locatePossession = allPossessions.find(x => x.possessionId === id);
        if (locatePossession) {
            return locatePossession;
        }
        return {} as IFilterablePossession;
    }


    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}>
                <Box
                    style={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white',
                        padding: '10px'
                    }}
                >
                    <Typography variant="h6" >
                        Possession Viewer Help
                    </Typography>
                    <Typography sx={{ mt: 2 }}>This page allows the user to sort and view all offensive possessions from each team</Typography>
                    <Typography sx={{ mt: 2 }}>- To use, select a team from the dropdown menu at the top</Typography>
                    <Typography sx={{ mt: 2 }}>- The left column contains filters to narrow the number of possessions shown</Typography>
                    <Typography sx={{ mt: 2 }}>- Click "show data" to display all possessions which fit the selected criteria</Typography>
                    <Typography sx={{ mt: 2 }}>- Summary statistics of the selected view are shown to the right of the field</Typography>
                    <Typography sx={{ mt: 2 }}>- Hover over data points to show more details</Typography>
                    <Typography sx={{ mt: 2 }}>- Hover over table columns and descriptions for definition</Typography>
                    <Typography sx={{ mt: 2 }}>- Legend: "blue circle" = possession start location; "red circle" = turnover location;
                        "green circle" = goal; "orange triangle" = timeout; "purple cross" = injury</Typography>
                    <Typography sx={{ mt: 2 }}>- NOTE: while displaying a lot of data it can be slow to change the filter settings.
                        I recommend clicking "Reset Data" and then making your new selections if you encounter this issue</Typography>
                </Box>
            </Modal>
            <div className='fieldFilters'>
                {/* GAME FILTER */}
                <CategoricalSelector
                    label="Games"
                    selected={selectedGames}
                    available={games}
                    selectNone={selectNoGames}
                    selectAll={selectAllGames}
                    renderFcn={renderSelectedGameValue}
                    changeFcn={handleChangeSelectedGames}
                />

                {/* X, Y, Possession filters */}
                <FormGroup className="filter" sx={{ marginBottom: "0px" }}>
                    <FormControlLabel control={<Switch checked={xFilters} onChange={handleXFilter} />} label="Enable x-axis filters" />
                    {xFilters && <FormControlLabel control={<Switch checked={xInvert} onChange={handleXInvert} />} label="Invert X filter" />}
                    {xFilters && <FieldAreaSlider low={xLow} high={xHigh} setLow={setXLow} setHigh={setXHigh} min={xMin / multiplier} max={xMax / multiplier} track={xInvert ? "inverted" : "normal"} />}
                    <FormControlLabel control={<Switch checked={yFilters} onChange={handleYFilter} />} label="Enable y-axis filters" />
                    {yFilters && <FormControlLabel control={<Switch checked={yInvert} onChange={handleYInvert} />} label="Invert Y filter" />}
                    {yFilters && <FieldAreaSlider low={yLow} high={yHigh} setLow={setYLow} setHigh={setYHigh} min={yMin / multiplier} max={yMax / multiplier} track={yInvert ? "inverted" : "normal"} />}
                    <FormControlLabel control={<Switch checked={possessionFilters} onChange={handlePossessionFilter} />} label="Enable possession filters" />
                    {possessionFilters &&
                        (<>
                            <CategoricalSelector
                                label="Possession number on point"
                                selected={selectedPointPossessionNumbers}
                                available={pointPossessionNumbers}
                                selectNone={selectNoPosNumbers}
                                selectAll={selectAllPosNumbers}
                                renderFcn={renderSelectedPointPossessionValue}
                                changeFcn={handleChangePointPossessionNumbers}
                                marginTop={10}
                            />
                            <FormControlLabel sx={formControlLabel} control={<Checkbox id="lastPos" checked={lastPosOfQ} onChange={handleLastPosOfQ} disableRipple />} label="End of quarter posessions only" />
                        </>
                        )}
                </FormGroup>

                {/* Quarter Filters */}
                <FormGroup className='filter' row={true} >
                    <FormControlLabel sx={formControlLabel} control={<Checkbox id="q1" checked={q1} onChange={handleQuarter} disableRipple />} label="Q1" />
                    <FormControlLabel sx={formControlLabel} control={<Checkbox id="q2" checked={q2} onChange={handleQuarter} disableRipple />} label="Q2" />
                    <FormControlLabel sx={formControlLabel} control={<Checkbox id="q3" checked={q3} onChange={handleQuarter} disableRipple />} label="Q3" />
                    <FormControlLabel sx={formControlLabel} control={<Checkbox id="q4" checked={q4} onChange={handleQuarter} disableRipple />} label="Q4" />
                    <FormControlLabel sx={formControlLabel} control={<Checkbox id="q5" checked={q5} onChange={handleQuarter} disableRipple />} label="OT" />
                    <FormControlLabel sx={formControlLabel} control={<Checkbox id="q6" checked={q6} onChange={handleQuarter} disableRipple />} label="2OT" />
                </FormGroup>

                {/* Off/Def/last of Q filters */}
                <FormGroup row={true} className="filter" sx={{ marginBottom: "10px" }}>
                    <FormControlLabel sx={formControlLabel} control={<Checkbox id="oPoints" checked={oPoints} onChange={handleOPoints} disableRipple />} label="Off situations" />
                    <FormControlLabel sx={formControlLabel} control={<Checkbox id="dPoints" checked={dPoints} onChange={handleDPoints} disableRipple />} label="Def situations" />
                    <FormControlLabel sx={{ formControlLabel }} control={<Checkbox checked={lastPointOfQ} onChange={handleLastPointOfQ} disableRipple />} label="Last points of quarter only" />
                </FormGroup>

                {/* Point Margin */}
                <DoubleIntPicker
                    label="Point Margin" marginSize={marginSize}
                    lowValue={marginLow} lowChange={handleMarginLow} lowBlur={handleBlurMarginLow}
                    highValue={marginHigh} highChange={handleMarginHigh} highBlur={handleBlurMarginHigh}
                    helptext="Set range for difference in score between two teams. Default values are set to include all"
                />

                {/* Time remaining in Q */}
                <GameTimeInput
                    label="Time remaining in quarter (point start)" marginSize={marginSize}
                    minutes={minutes} minutesChange={handleMinutes} minutesBlur={handleMinutesBlur}
                    seconds={seconds} secondsChange={handleSeconds} secondsBlur={handleSecondsBlur}
                    helptext="Set maximum time remaining in quarter at start of point"
                />

                {/* Buttons */}
                <ButtonGroup sx={{ paddingTop: "10px", width: "100%" }}>
                    <Button onClick={updateDataDisplay}>
                        Show data
                    </Button>
                    <Button onClick={hideData} >
                        Reset
                    </Button>
                    <Button onClick={handleOpen} >
                        Show Help
                    </Button>
                </ButtonGroup>

            </div>
            <Field possessions={passes} display={display} setActivePossessionId={setActivePossessionId} setActivePassId={setActivePassId} activePassId={activePassId} />
            <div className='infoColumn'>
                <SummaryTable passes={getPossessionPasses(activePossessionId)} activePassId={activePassId} possession={getPossession(activePossessionId)} possessions={selectedPossessions} />
            </div >
        </>
    );
}
