import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IFilterablePass from '../dto/interfaces/IFilterablePass';
import IFilterablePossession from '../dto/interfaces/IFilterablePossession';
import { Tooltip, Typography } from '@mui/material';

interface IProps {
    passes: IFilterablePass[];
    activePassId: number;
    possession: IFilterablePossession;
    possessions: IFilterablePossession[];
}

// This whole thing sucks I'd like it to be different but not a priority
export default function SummaryTable({ passes, activePassId, possession, possessions }: IProps) {
    const currentPass = passes?.find(x => x.passId === activePassId);

    const getNextPass = () => {
        const nextPass = activePassId + 1;
        return passes?.find(x => x.passId === nextPass);
    }

    const getNPasses = () => {
        return passes.length - 1
    }

    const getYardsY = (passArr: IFilterablePass[]) => {
        return passArr.map(item => item.yardsY).reduce((prv, nxt) => prv + nxt)
    }

    const getYdsPerPass = () => {
        return getYardsY(passes) / (passes.length - 1);
    }

    const getTotalGoals = () => {
        return possessions.filter(x => x.result === "GOAL").length;
    }

    const getTotalTurnovers = () => {
        const turnoverPlays = ["BLOCK", "DROP", "THROWING_ERROR", "CALLAHAN"]
        return possessions.filter(x => turnoverPlays.includes(x.result)).length;
    }

    const getNumberOfPoints = () => {
        return [... new Set(possessions.map(pos => pos.pointId))].length
    }

    const makeRow = (pass: IFilterablePass | undefined, next = false) => {
        const nextPassId = activePassId + 1;
        const usePass = next ? passes.find(x => x.passId === nextPassId) : pass;
        return usePass ? (
            <>
                <TableCell>{next ? "Next Pass" : "Previous Pass"}</TableCell>
                <TableCell>{usePass.throwerId}</TableCell>
                <TableCell>{Math.round(usePass.throwerX as number)}</TableCell>
                <TableCell>{Math.round(usePass.throwerY as number)}</TableCell>
                <TableCell>{usePass.targetId}</TableCell>
                <TableCell>{Math.round(usePass.targetX as number)}</TableCell>
                <TableCell>{Math.round(usePass.targetY as number)}</TableCell>
                <TableCell>{Math.round(usePass.yardsThrowing as number)}</TableCell>
                <TableCell>{Math.round(usePass.yardsAir as number)}</TableCell>
                <TableCell>{usePass?.passNumber}</TableCell>
            </>
        ) : (<></>);
    }

    return (
        <>
            {possessions.length > 0 && (
                <>
                    <Typography sx={{ marginTop: '0px', width: "59%" }} variant="h5"> View Stats </Typography>
                    <TableContainer sx={{ width: '250px' }} component={Paper}>
                        <Table size="small">
                            <TableRow>
                                <TableCell>Total possessions</TableCell>
                                <TableCell>{possessions.length}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Goals</TableCell>
                                <TableCell>{getTotalGoals()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Turnovers</TableCell>
                                <TableCell>{getTotalTurnovers()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <Tooltip title="Goals scored / (goals scored + turnovers)" placement='top'><TableCell>Goal rate (possession)</TableCell></Tooltip>
                                <TableCell>{(getTotalGoals() / (getTotalTurnovers() + getTotalGoals())).toFixed(3)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <Tooltip title="Goals scored / number of unique points (note: only includes points where team possessed the disc)" placement='top'>
                                    <TableCell>Goal rate (point)</TableCell>
                                </Tooltip>
                                <TableCell>{(getTotalGoals() / getNumberOfPoints()).toFixed(3)}</TableCell>
                            </TableRow>
                        </Table>
                    </TableContainer>
                </>)
            }

            {passes.length > 0 && <>
                <Typography sx={{ marginTop: '10px', width: "59%" }} variant="h5"> Pass Details </Typography>
                <TableContainer sx={{ width: "100%" }} component={Paper}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell></TableCell>
                                <Tooltip title="Thrower" placement='top'><TableCell>Thrower</TableCell></Tooltip>
                                <Tooltip title="Thrower X location" placement='top'><TableCell>ThrX</TableCell></Tooltip>
                                <Tooltip title="Thrower Y location" placement='top'><TableCell>ThrY</TableCell></Tooltip>
                                <Tooltip title="Target" placement='top'><TableCell>Target</TableCell></Tooltip>
                                <Tooltip title="Target X location" placement='top'><TableCell>TarX</TableCell></Tooltip>
                                <Tooltip title="Target Y location" placement='top'><TableCell>TarY</TableCell></Tooltip>
                                <Tooltip title="Vertical yards" placement='top'><TableCell>Yds</TableCell></Tooltip>
                                <Tooltip title="Total distance traveled" placement='top'><TableCell>Dist</TableCell></Tooltip>
                                <Tooltip title="Pass number on possession" placement='top'><TableCell>N</TableCell></Tooltip>
                            </TableRow>

                            <TableRow>
                                {makeRow(currentPass)}
                            </TableRow>
                            <TableRow>
                                {makeRow(getNextPass(), true)}
                            </TableRow>
                        </TableBody>
                    </Table>

                </TableContainer>
            </>}

            {possession && passes.length > 0 &&
                <>
                    <Typography sx={{ marginTop: '10px', width: "59%" }} variant="h5"> Possession Details </Typography>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <Tooltip title="Start X" placement='top'><TableCell>SX</TableCell></Tooltip>
                                    <Tooltip title="Start Y" placement='top'><TableCell>SY</TableCell></Tooltip>
                                    <Tooltip title="End X" placement='top'><TableCell>EX</TableCell></Tooltip>
                                    <Tooltip title="End Y" placement='top'><TableCell>EY</TableCell></Tooltip>
                                    <Tooltip title="Total horizontal yards" placement='top'><TableCell>YdsX</TableCell></Tooltip>
                                    <Tooltip title="Vertical yards gained" placement='top'><TableCell>YdsY</TableCell></Tooltip>
                                    <Tooltip title="Cumulative distance of passes" placement='top'><TableCell>Dist</TableCell></Tooltip>
                                    <Tooltip title="Number of passes" placement='top'><TableCell>N</TableCell></Tooltip>
                                    <Tooltip title="YdsY per attempted pass" placement='top'><TableCell>YPA</TableCell></Tooltip>
                                    <Tooltip title="Possession Result" placement='top'><TableCell>Result</TableCell></Tooltip>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{Math.round(possession.startX)}</TableCell>
                                    <TableCell>{Math.round(possession.startY)}</TableCell>
                                    <TableCell>{Math.round(possession.endX)}</TableCell>
                                    <TableCell>{Math.round(possession.endY)}</TableCell>
                                    <TableCell>{Math.round(passes.map(item => item.yardsX).reduce((prv, nxt) => prv + nxt))}</TableCell>
                                    <TableCell>{Math.round(getYardsY(passes))}</TableCell>
                                    <TableCell>{Math.round(passes.map(item => item.yardsAir).reduce((prv, nxt) => prv + nxt))}</TableCell>
                                    <TableCell>{getNPasses()}</TableCell>
                                    <TableCell>{Math.round(getYdsPerPass() * 10) / 10}</TableCell>
                                    <TableCell>{possession?.result}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>}
        </>
    );
}