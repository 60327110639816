import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Desktop from './Desktop';
import Mobile from './Mobile'
import { maxWidth } from '../../constants/MobilePixelWidth';
import api from '../../api_urls';
import axios from 'axios';

export interface IWelcomeProps {
    testConnection: () => void;
    getColor: () => string;
}

export default function PlayerStats() {
    const isMobile = useMediaQuery(`(max-width:${maxWidth}px)`);
    const [booted, setBooted] = useState(false);

    async function testConnection() {
        const ready = await axios.get<string>(`${api.ROOT}${api.REFRESH_CONNECTION}`);
        setBooted(ready.status === 200);
    }

    const getColor = () => {
        return booted ? "green" : "red";
    }

    return (
        <>
            {isMobile ? <Mobile testConnection={testConnection} getColor={getColor} /> : <Desktop testConnection={testConnection} getColor={getColor} />}
        </>
    );
}
