import React, { useEffect, useState } from 'react';
import CustomGrid from '../../components/CustomGrid'
import SelectATeam from '../../components/SelectATeam';
import { BaseStatsColumns } from '../../dto/BaseStatsColumns'
import axios from 'axios';
import api from '../../api_urls';
import { SelectChangeEvent } from '@mui/material/Select';
import { useParams } from 'react-router-dom';
import ITeamSeason from '../../dto/interfaces/ITeamSeason';
import IPlayer from '../../dto/interfaces/IPlayer';
import IBaseStatsColumns from '../../dto/interfaces/IBaseStatsColumns';
import DefaultTeam from '../../constants/DefaultTeam';
import DefaultPlayer from '../../constants/DefaultPlayer';
import { useRecoilValue } from 'recoil';
import { headerObject } from '../../state/store/Selectors';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import Passes from '../subviews/players/Passes';
import { PassType } from '../../components/FilterPasses';

export default function Desktop() {
    const { playerId } = useParams();
    const defaultTeam = new DefaultTeam();
    var defaultPlayer = new DefaultPlayer(playerId);
    const [team, setTeam] = useState(defaultTeam);
    const [teamList, setTeamList] = useState<ITeamSeason[]>([]);
    const [tableData, setTableData] = useState<IBaseStatsColumns[]>([]);
    const [teamPlayers, setTeamPlayers] = useState<IPlayer[]>([]);
    const [player, setPlayer] = useState<IPlayer>(defaultPlayer);
    const header = useRecoilValue(headerObject);
    const [tabValue, setTabValue] = useState("0");

    useEffect(() => {
        if (team?.team?.ext_team_id) {
            axios.get<IPlayer[]>(`${api.ROOT}${api.TEAMS}/${team.id}/players`, header)
                .then(res => {
                    setPlayer(defaultPlayer);
                    var players = res.data.sort((a, b) => {
                        if (a.first_name < b.first_name) { return -1; }
                        if (a.first_name > b.first_name) { return 1; }
                        return 0;
                    })
                    setTeamPlayers(players);
                })
        }
        else {
            setPlayer(defaultPlayer);
            setTeam(defaultTeam);
        }
    }, [team])

    useEffect(() => {
        if (player) {
            axios.get<IBaseStatsColumns[]>(`${api.ROOT}${api.PLAYERS}/${player?.ext_player_id}`, header)
                .then(res => {
                    res.data.sort((a, b) => {
                        if (a.id < b.id) { return -1; }
                        if (a.id > b.id) { return 1; }
                        return 0;
                    });
                    setTableData(res.data);
                })
        }
    }, [player])

    const handleTeamChange = (event: SelectChangeEvent) => {
        const selectThisTeam = teamList.find(i => i.team.ext_team_id === event.target.value) as ITeamSeason;
        setTeam(selectThisTeam);
    }

    const handlePlayerChange = (event: SelectChangeEvent) => {
        defaultPlayer = new DefaultPlayer(""); // reset so that you don't access 
        const selectThisPlayer = teamPlayers.find(i => i.ext_player_id === event.target.value) as IPlayer;
        selectThisPlayer ? setPlayer(selectThisPlayer) : setPlayer(defaultPlayer);
        window.history.pushState({}, "", `/players/${event.target.value}`);
    }

    const changeTab = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    }

    return (
        <div className="basicViewBody">
            <SelectATeam
                handleChange={handleTeamChange}
                selectedTeam={team}
                setTeamList={setTeamList}
                teamList={teamList}
                playerSelect={true}
                handlePlayerChange={handlePlayerChange}
                player={player}
                teamPlayers={teamPlayers}
            />

            <TabContext value={tabValue}>
                <TabList onChange={changeTab}>
                    <Tab label="box score" value="0" />
                    <Tab label="passes" value="1" />
                    <Tab label="receptions" value="2" />
                </TabList>
                {tabValue === "0" && (<TabPanel value="0" className="teamsTab" sx={{ padding: '0px' }}>
                    <CustomGrid rows={tableData} columns={BaseStatsColumns} />
                </TabPanel>)}
                {tabValue === "1" && (<TabPanel value="1" className="teamsTab" sx={{ padding: '0px' }}>
                    <Passes team={team} player={player} type={PassType.thrower} />
                </TabPanel>)}
                {tabValue === "2" && (<TabPanel value="2" className="teamsTab" sx={{ padding: '0px' }}>
                    <Passes team={team} player={player} type={PassType.target} />
                </TabPanel>)}
            </TabContext>
        </div>
    );
}
