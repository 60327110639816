import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import ITeamSeason from '../../dto/interfaces/ITeamSeason';
import api from '../../api_urls';
import { headerObject } from '../../state/store/Selectors';
import axios from 'axios';
import IXy from '../../dto/interfaces/IXy';
import { AgChartsReact } from 'ag-charts-react';
import { AgChartOptions, AgSeriesTooltipRendererParams } from 'ag-charts-community';
import { teamColors, audlColor } from '../../theme/TeamColors';
import { selectedSeasonId } from '../../state/store/Atoms';
import { Button } from '@mui/material';
import MultiIntPicker from '../MultiIntPicker';
import IIntPickerDef from '../interfaces/IIntPickerDef';

interface IProps {
    team: ITeamSeason
}

export default function TurnoverXLocation({ team }: IProps) {
    const defaultYMax = 12;
    const header = useRecoilValue(headerObject);
    const seasonId = useRecoilValue(selectedSeasonId);
    const [data, setData] = useState<IXy[]>([])
    const [leagueData, setLeagueData] = useState<IXy[]>([]);
    const defaultBinWidth = 3;
    const [binWidth, setBinWidth] = useState<number | string>(defaultBinWidth);
    const defaultMinPassYds = -120;
    const [minPassYds, setMinPassYds] = useState<number | string>(defaultMinPassYds);
    const [fetchTrigger, triggerFetch] = useState(false);

    useEffect(() => {
        Promise.all([
            getTurnoverData(team.id).then((returnedData) => {
                setData(returnedData);
            }),
            getTurnoverData("league").then((returnedData) => {
                setLeagueData(returnedData);
            })

        ]);
    }, [team, fetchTrigger])

    async function getTurnoverData(teamId: number | string) {
        const res = await axios.post<IXy[]>(`${api.ROOT}${api.TEAMS}/${team.team.ext_team_id}${api.TURNOVER_LOCATIONS}`, { "binWidth": binWidth, "seasonId": seasonId, "teamId": teamId, passYds: minPassYds }, header)
        return res.data;
    }

    const getColor = (tm: ITeamSeason) => {
        return teamColors.find(x => x.team_ext_id === tm.team.ext_team_id)?.color
    }

    const setYMax = (teamData: IXy[], audlData: IXy[]) => {
        const teamMax = Math.max(...teamData.map(value => value.y)) > defaultYMax ? Math.max(...teamData.map(value => value.y)) : defaultYMax;
        const leagueMax = Math.max(...audlData.map(value => value.y)) > defaultYMax ? Math.max(...audlData.map(value => value.y)) : defaultYMax;
        return Math.max(teamMax, leagueMax);
    }


    const options: AgChartOptions = {
        autoSize: true,
        legend: {
            enabled: true
        },
        title: {
            text: 'Turnover % by distance from sideline',

        },
        subtitle: {
            text: '(dropped passes excluded)',
        },
        series: [
            {
                data: data,
                title: `${team.team.ext_team_id}`,
                xKey: 'x',
                yKey: 'y',
                stroke: getColor(team),
                showInLegend: true,
                tooltip: {
                    renderer: function (params: AgSeriesTooltipRendererParams) {
                        return {
                            content: `${params.datum.y.toFixed(2)}% \n N=${params.datum.n}`
                        }
                    }
                },
                marker: {
                    fill: getColor(team),
                    stroke: getColor(team)
                },
            },
            {
                data: leagueData,
                title: "AUDL",
                xKey: 'x',
                yKey: 'y',
                stroke: audlColor,
                showInLegend: true,
                tooltip: {
                    renderer: function (params: AgSeriesTooltipRendererParams) {
                        return {
                            content: `${params.datum.y.toFixed(2)}% N=${params.datum.n}`
                        }
                    }
                },
                marker: {
                    fill: audlColor,
                    stroke: audlColor
                }
            },
        ],
        axes: [
            {
                type: 'number',
                position: 'bottom',
                tick: {
                    count: data.length
                },
                title: {
                    text: 'Distance from sideline (yds)',
                    enabled: true
                }
            },
            {
                type: 'number',
                position: 'left',
                min: 0,
                max: setYMax(data, leagueData),
                title: {
                    text: 'Turnover %',
                    enabled: true
                }
            }
        ]
    }

    const handleBlur = () => {

    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        var val = '';
        if (event.target.value !== val) {
            val = event.target.value;
        }
        switch (event.target.id) {
            case "binWidth":
                if (Number(val) < 0) {
                    setBinWidth(1);
                } else {
                    setBinWidth(val);
                }
                break;
            case "passYdsMin":
                if (Number(val) > 99) {
                    setMinPassYds(99)
                } else if (Number(val) < -120) {
                    setMinPassYds(-120)
                } else {
                    setMinPassYds(val);
                }
                break;
        }
    }

    const handleResetFilters = () => {
        setBinWidth(defaultBinWidth);
        setMinPassYds(defaultMinPassYds);
    }

    const handleFetchData = () => {
        triggerFetch(!fetchTrigger);
    }

    const binWidthDef: IIntPickerDef = {
        value: binWidth,
        label: "Aggregation width",
        id: "binWidth"
    }

    const passYdsDef: IIntPickerDef = {
        value: minPassYds,
        label: "Min passing yards",
        id: "passYdsMin"
    }

    const pickerValues: IIntPickerDef[] = [
        binWidthDef,
        passYdsDef
    ]


    return (
        <div className='charts'>
            <MultiIntPicker values={pickerValues} handleChange={handleChange} handleBlur={handleBlur} marginSize={10}></MultiIntPicker>
            <div className='equalSpace'>
                <Button onClick={handleFetchData}>Apply Filters</Button>
                <Button onClick={handleResetFilters}>Reset Filters</Button>
            </div>
            <AgChartsReact
                options={options}
            />
        </div>
    )
}