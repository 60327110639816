import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid'
import { ColDef } from 'ag-grid-community';

const base_width = 55
const sortable = true;

const default_params: ColDef = {
    width: base_width,
    type: 'numericColumn',
    sortable: true
}

export const BaseStatsColumns: ColDef[] = [
    {
        field: 'name',
        headerName: 'Player',
        width: 150
    }, {
        ...default_params,
        field: 'tPoints',
        headerName: 'PP',
        headerTooltip: 'Total Points played (starting with pull)',

    }, {
        ...default_params,
        field: 'oPoints',
        headerName: 'OPP',
        headerTooltip: 'Offensive points played',
    }, {
        ...default_params,
        field: 'dPoints',
        headerName: 'DPP',
        headerTooltip: 'Defensive points played'
    }, {
        ...default_params,
        field: 'assists',
        headerName: 'AST',
        headerTooltip: 'Assists'
    }, {
        ...default_params,
        field: 'goals',
        headerName: 'GLS',
        headerTooltip: 'Goals'
    }, {
        ...default_params,
        field: 'blocks',
        headerName: 'BLK',
        headerTooltip: 'Blocks'
    }, {
        ...default_params,
        field: 'plusminus',
        headerName: '+/-',
        headerTooltip: 'Goals + Assists + Blocks - Drops - Throwing errors - Stalls'
    }, {
        ...default_params,
        field: 'yardsReceiving',
        headerName: 'RcY',
        headerTooltip: 'Receiving yards',
        valueFormatter: (params) => {
            return Math.round(Number(params.value)).toString();
        }
    }, {
        ...default_params,
        field: 'yardsThrowing',
        headerName: 'ThY',
        headerTooltip: 'Throwing yards',
        valueFormatter: (params) => {
            return Math.round(Number(params.value)).toString();
        }
    }, {
        ...default_params,
        field: 'yardsTotal',
        headerName: 'Yds',
        headerTooltip: 'Throwing + receiving yards',
        valueFormatter: (params) => {
            return Math.round(Number(params.value)).toString();
        }
    }, {
        ...default_params,
        field: 'completions',
        headerName: 'Cmp',
        headerTooltip: 'Number of Completions'
    }, {
        ...default_params,
        field: 'completionPct',
        headerName: 'Cmp%',
        width: 80,
        headerTooltip: 'Completions / pass attemps',
        valueFormatter: (params) => {
            return (Math.round(Number(params.value) * 1000) / 1000).toFixed(3);
        }
    }, {
        ...default_params,
        field: 'secondAssists',
        headerName: 'HA',
        headerTooltip: 'Hockey assists'
    }, {
        ...default_params,
        field: 'throwingTurns',
        headerName: 'T',
        headerTooltip: 'Throwaways'
    }, {
        ...default_params,
        field: 'stalls',
        headerName: 'S',
        headerTooltip: 'Times stalled'
    }, {
        ...default_params,
        field: 'drops',
        headerName: 'D',
        headerTooltip: 'Passes dropped'
    }
]