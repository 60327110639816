import React from "react";
import { useAuth0 } from "@auth0/auth0-react";


const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();


    async function loginAndSave() {
        window.sessionStorage.setItem('redirect', window.location.href);
        await loginWithRedirect();
    }
    return <button onClick={() => loginAndSave()}>Log In</button>;
};

export default LoginButton;