const devBase = "http://localhost:8080";
const prodBase = "https://audl-stats.azurewebsites.net"

const api_urls = {
    ROOT: process.env.NODE_ENV === "development" ? devBase : prodBase,

    ALLGAMES: "/allgames",
    ALL_THROWS: "/throws",
    ALL_TARGETS: "/targets", // misleading name following convention. Will not show targets scored as a throwaway
    BOXSTATS: "/box",
    GAME_HEADER_INFO: "/headerinfo",
    GAMES: "/games",
    ONOFF: "/onoff",
    PASSES_FROM_POSSESSIONS: "/passes",
    PLAYERS: "/players",
    POINT_VALUE: "/pointvaluebytime",
    POSSESSIONS: "/possessions",
    REFRESH_CONNECTION: "/refresh-connection",
    TEAM_SEASON: "/teamSeason",
    TEAMS: "/teams",

    GAMES_PLAYED: "/gamesplayed",
    TURNOVER_LOCATIONS: "/turnoverlocations",

    EXTERNAL: "/external",
    FOLDERS: "/folders",
    PAGE: "?page=",
    VIDEOS: "/videos",
    VIMEO: "/vimeo",
    ADMIN: "/administration",
    UPDATE_VIDEOS: "/update-video-catalog",
    UPDATE_FOLDERS: "/update-folder-catalog",


    OPT_OUT: "/opt-out",
    REPORT_BUG: "/createbug"
}

export default api_urls;