// Set these values
export const multiplier = 3; // have to use this because scatter point size cannot be < 1
export const fieldWidth = 53.5 * multiplier;
export const fieldLength = 80 * multiplier;
export const endzoneDepth = 20 * multiplier;
const fieldMarkingWeight = 1 * multiplier;



// Below values are derived
export const yMin = 0;
export const yMax = fieldLength + 2 * endzoneDepth;
export const xMin = -(fieldWidth / 2);
export const xMax = fieldWidth / 2;



const quarterField = fieldLength / 4;
const eigthField = fieldLength / 8;


export const borderStyle = {
    data: {
        stroke: "black",
        strokeWidth: `${.5 * fieldMarkingWeight}`
    }
}

export const majorStyle = {
    data: {
        stroke: "black",
        strokeWidth: `${.2 * fieldMarkingWeight}`
    }
}

export const minorStyle = {
    data: {
        stroke: "grey",
        strokeWidth: `${.1 * fieldMarkingWeight}`
    }
}

export const fieldStyle = {
    background: {
        fill: "rgba(36, 156, 203, 0.27)"
    },
}

export const fieldBordersPortrait = [
    { x: xMin, y: 0 },
    { x: xMin, y: yMax },
    { x: xMax, y: yMax },
    { x: xMax, y: 0 },
    { x: xMin, y: 0 },

]

export const majorMarksPortrait = [
    { x: xMin, y: endzoneDepth },
    { x: xMax, y: endzoneDepth },
    { x: null, y: null },
    { x: xMin, y: endzoneDepth + fieldLength },
    { x: xMax, y: endzoneDepth + fieldLength },
    { x: null, y: null },
    { x: xMin, y: quarterField + endzoneDepth },
    { x: xMax, y: quarterField + endzoneDepth },
    { x: null, y: null },
    { x: xMin, y: 2 * quarterField + endzoneDepth },
    { x: xMax, y: 2 * quarterField + endzoneDepth },
    { x: null, y: null },
    { x: xMin, y: 3 * quarterField + endzoneDepth },
    { x: xMax, y: 3 * quarterField + endzoneDepth }
]

export const minorMarksPortrait = [
    { x: xMin, y: eigthField + endzoneDepth },
    { x: xMax, y: eigthField + endzoneDepth },
    { x: null, y: null },
    { x: xMin, y: 3 * eigthField + endzoneDepth },
    { x: xMax, y: 3 * eigthField + endzoneDepth },
    { x: null, y: null },
    { x: xMin, y: 5 * eigthField + endzoneDepth },
    { x: xMax, y: 5 * eigthField + endzoneDepth },
    { x: null, y: null },
    { x: xMin, y: 7 * eigthField + endzoneDepth },
    { x: xMax, y: 7 * eigthField + endzoneDepth }
]
