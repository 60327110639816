import { Button, Modal, Typography } from '@mui/material';
import Vimeo from '@u-wave/react-vimeo';
import React, { useState } from 'react';
import VideoCatalog from '../../components/VideoCatalog';
import IVideo from '../../dto/interfaces/vimeo/IVideo';

interface IProps {
    video: IVideo;
    setVideo: (video: IVideo) => void;
    descriptionLines: (raw: string) => string[];
}

export default function Mobile({ video, setVideo, descriptionLines }: IProps) {
    const [modalOpen, setModalOpen] = useState(false);
    const buttonDefaultText = "Select a video"
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    const setVideoAndButton = (newVideo: IVideo) => {
        setVideo(newVideo)
    }

    return (
        <div className="videoBody">
            <Button
                onClick={handleOpen}
                variant="outlined"
                sx={{ width: "100%", marginBottom: "10px" }}
            >
                {video.name || buttonDefaultText}
            </Button>

            <Modal open={modalOpen} onClose={handleClose} sx={{ overflow: "scroll", margin: "10px", }}>
                <VideoCatalog closeModal={handleClose} setVideo={setVideoAndButton} />
            </Modal>
            <div>
                {video.uri && <Vimeo video={`${video.uri}`} width={"300px"} height={"200px"} />}
                {descriptionLines(video.description).map((line) => {
                    return (
                        <Typography variant="body1">{line}</Typography>
                    )
                })}
            </div>
        </div>
    );
}