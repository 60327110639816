import React from 'react';
import { useMediaQuery } from '@mui/material';
import Desktop from './Desktop';
import Mobile from './Mobile'
import { maxWidth } from '../../constants/MobilePixelWidth';

export default function GameStats() {
    const isMobile = useMediaQuery(`(max-width:${maxWidth}px)`);
    return (
        <>
            {isMobile ? <Mobile /> : <Desktop />}
        </>
    );
}
