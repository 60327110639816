import React, { useEffect, useState } from 'react';
import CustomGrid from '../../components/CustomGrid';
import SelectATeam from '../../components/SelectATeam';
import { BaseStatsColumns } from '../../dto/BaseStatsColumns'
import axios from 'axios';
import api from '../../api_urls';
import { SelectChangeEvent } from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useParams } from 'react-router-dom';
import ITeamSeason from '../../dto/interfaces/ITeamSeason';
import IBaseStatsColumns from '../../dto/interfaces/IBaseStatsColumns';
import DefaultTeam from '../../constants/DefaultTeam';
import { headerObject } from '../../state/store/Selectors';
import { useRecoilValue } from 'recoil';
import PossessionViewer from '../subviews/teams/PossessionViewer';
import { defaultTeamName } from '../../state/store/Atoms';
import OnOff from '../subviews/teams/Onoff';
import Charts from '../subviews/teams/Charts';

export default function TeamStats() {
    const { selectedTeam } = useParams();
    const defaultTeam = new DefaultTeam(selectedTeam);
    const [teamList, setTeamList] = useState<ITeamSeason[]>([]);
    const [team, setTeam] = useState(defaultTeam);
    const [tabValue, setTabValue] = useState("0");
    const [tableData, setTableData] = useState<IBaseStatsColumns[]>([]);
    const header = useRecoilValue(headerObject);
    const teamNameIfBlank = useRecoilValue(defaultTeamName);

    // TODO: there is a memory leak warning here (and in similar place)
    // spend a day plus trying to fix it, no dice. Ignoring and move on. 
    // See ADO for more info
    useEffect(() => {
        if (team?.team?.ext_team_id && team?.team?.ext_team_id !== teamNameIfBlank) {
            Promise.all([
                getTeamData().then(setTableData)
            ]);
        }
    }, [team])

    async function getTeamData(): Promise<any> {
        const res = await axios.get<IBaseStatsColumns[]>(`${api.ROOT}${api.TEAMS}/${team.team.ext_team_id}${api.BOXSTATS}`, header);
        return res.data.filter(i => i.tPoints > 0);
    }

    const handleTeamChange = (event: SelectChangeEvent) => {
        const selectThisTeam = teamList.find(i => i.team.ext_team_id === event.target.value) as ITeamSeason;
        setTeam(selectThisTeam ? selectThisTeam : defaultTeam);
        window.history.pushState({}, "", `/teams/${event.target.value}`);
    }

    const changeTab = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    }

    return (
        <div className="basicViewBody">
            <SelectATeam handleChange={handleTeamChange} selectedTeam={team} setTeamList={setTeamList} teamList={teamList} playerSelect={false} />
            <TabContext value={tabValue}>
                <TabList onChange={changeTab}>
                    <Tab label="box score" value="0" />
                    <Tab label="on/off" value="1" />
                    <Tab label="possessions" value="2" />
                    <Tab label="charts" value="3" />
                </TabList>
                {tabValue === "0" && (<TabPanel value="0" className="teamsTab" sx={{ padding: '0px' }}>
                    {team?.team?.ext_team_id !== "default" && <CustomGrid rows={tableData} columns={BaseStatsColumns} />}
                </TabPanel>)}
                {tabValue === "1" && (<TabPanel className='teamsTab' value="1" sx={{ padding: '0px' }}>
                    {team?.team?.ext_team_id !== "default" && <OnOff team={team} />}
                </TabPanel>)}
                {tabValue === "2" && (<TabPanel value="2" className='teamsPanel' sx={{ padding: '0px' }}>
                    <PossessionViewer team={team} />
                </TabPanel>)}
                {tabValue === "3" && (<TabPanel value="3" className='teamsPanel' sx={{ padding: '0px' }}>
                    <Charts team={team} teamList={teamList} />
                </TabPanel>)}
            </TabContext>
        </div>
    );
}
