import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { RecoilRoot } from 'recoil';
import { config } from './auth0config';

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Auth0Provider
                domain={config.domain}
                clientId={config.clientId}
                redirectUri={window.location.origin}
                audience={config.audience}
            >
                <RecoilRoot>
                    <App />
                </RecoilRoot>
            </Auth0Provider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
