import { ThemeOptions } from '@mui/material/styles';

// const primary_main = '#414141'

// (matches WC logo background color)
export const primary_main = '#383a3c'

export const secondary_main = '#6d7c9c'

//ideally this is probably refactored into a CSS file...
export const drawerWidth = 200

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: primary_main,
        },
        secondary: {
            main: secondary_main,
        },
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: primary_main,
                    width: drawerWidth
                },

            },

        }
    }
};
