import { ReactElement } from 'react';
import UnauthorizedView from '../views/Unauthorized/UnauthorizedView';
import { useAuth0 } from '@auth0/auth0-react';
import { isAdmin } from '../state/store/Selectors';
import { useRecoilValue } from 'recoil';

export default function ProtectAdmin(component: ReactElement): ReactElement {
    const { isAuthenticated } = useAuth0();
    const admin = useRecoilValue(isAdmin)
    if (isAuthenticated && admin) {
        return component;
    }
    else {
        return <UnauthorizedView />;
    }
}