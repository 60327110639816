
import React from 'react';
import { FormControlLabel, FormGroup, TextField, Tooltip, Typography } from '@mui/material';

interface IProps {
    lowValue: number | string;
    highValue: number | string;
    lowChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    highChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    lowBlur: () => void;
    highBlur: () => void;
    marginSize: number;
    label: string;
    helptext: string;
}

export default function DoubleIntPicker({ lowValue, highValue, lowChange, highChange, lowBlur, highBlur, marginSize, label, helptext }: IProps) {
    const styles = { width: '60%', marginLeft: `${marginSize}px`, marginRight: `${marginSize}px`, marginBottom: `${marginSize / 2}px` }
    return (
        <FormGroup className='filter' row={true}>
            <Tooltip title={helptext} placement='top'><Typography sx={{ width: "100%" }}> {label} </Typography></Tooltip>
            <FormControlLabel control={
                <TextField
                    value={lowValue}
                    size="small"
                    onChange={lowChange}
                    onBlur={lowBlur}
                    type="number"
                    sx={styles}
                />
            }
                label="Min"
                sx={{ width: "50%", marginRight: "10px" }}
            />

            <FormControlLabel control={
                <TextField
                    value={highValue}
                    size="small"
                    onChange={highChange}
                    onBlur={highBlur}
                    type="number"
                    sx={styles}
                />
            }
                label="Max"
                sx={{ width: "50%", marginRight: "0px" }}
            />
        </FormGroup>
    );
}
