import React, { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import '../css/base.css';
import { AppBar, FormControl, IconButton, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import Auth0Logout from './Auth0Logout/Auth0Logout';
import { ROUTES, ROUTES_ADMIN, IRouteInfo } from '../constants/Routes';
import { useRecoilValue } from 'recoil';
import { isAdmin } from '../state/store/Selectors';

interface menuOption {
    name: string;
    icon: any;
    link: string;
}

// Hacky as heck way to determine which is selected to start
function determineStartingListItem() {
    const url = window.location.href;
    if (url.includes("games")) {
        return ("/games");
    }
    if (url.includes("players")) {
        return ("/players");
    }
    if (url.includes("teams")) {
        return ("/teams");
    }
    return "Home"
}

export default function BottomNav() {
    const admin = useRecoilValue(isAdmin)
    const navigate = useNavigate();
    const [page, setPage] = useState(determineStartingListItem())
    const menuOptionsFontColor: string = 'white'

    const goHome = (e: React.MouseEvent) => {
        setPage("/")
        navigate("/")
    }

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        setPage(event.target.value);
        navigate(event.target.value)
    }

    const renderPageTitle = () => {
        const selected = ROUTES.find(x => x.path === page)
        return selected ? selected.name : "Home";
    }

    const mapRoutes = (routes: IRouteInfo[]) => {
        return (
            routes.map((option: IRouteInfo) => {
                return (

                    <MenuItem
                        key={option.name}
                        value={option.path}
                    >
                        {option.name}
                    </MenuItem>
                )
            })
        )
    }

    return (
        <>
            <AppBar>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{}}
                        onClick={goHome}
                    >
                        <HomeIcon />
                    </IconButton>
                    <FormControl sx={{ flexGrow: 1 }}>
                        <Select
                            value={page}
                            onChange={handleSelectChange}
                            renderValue={renderPageTitle}
                            variant='standard'
                            disableUnderline
                            sx={{
                                color: `${menuOptionsFontColor}`,
                                "& .MuiSvgIcon-root": {
                                    color: `${menuOptionsFontColor}`,
                                }
                            }}
                        >
                            {mapRoutes(ROUTES)}
                            {admin && mapRoutes(ROUTES_ADMIN)}
                        </Select>
                    </FormControl>
                    {/* {icon(<LogoutIcon />, goHome)} */}
                    <Auth0Logout />
                </Toolbar>
            </AppBar>
            <Toolbar sx={{ width: "100%" }} /> {/* prevents app bar from covering content*/}
        </>
    );
}