import { Box, Button, FormControl, Typography } from '@mui/material';
import { IWelcomeProps } from './WelcomePage';

export default function Mobile({ testConnection, getColor }: IWelcomeProps) {
    return (
        <div id='welcome'>
            <FormControl sx={{ display: "flex", flexDirection: "column", height: "100vh", alignItems: "center" }}>
                <Typography variant="h3" sx={{ marginTop: "10px", marginBottom: "10px" }}>Welcome!</Typography>
                <Box sx={{ width: "50px", height: "50px", backgroundColor: getColor() }}></Box>
                <Button onClick={testConnection} sx={{ marginBottom: "10px", marginTop: "10px" }}>Test Connection</Button>
                <Typography variant="subtitle1" align='center'>Click this button to test the server connection</Typography>
                <Typography variant="subtitle1" align='center'>(It sleeps after a period of inactivity)</Typography>
                <Typography variant="subtitle1" align='center'>The box will turn green when it is ready</Typography>
                <Typography variant="subtitle1" align='center'>This can take up to 30ish seconds sometimes, please be patient</Typography>
                <Typography variant="subtitle1" align='center' sx={{ marginTop: "20px" }}>Presently, only viewing video is enabled for mobile screens.</Typography>
                <Typography variant="subtitle1" align='center'>Please use a full-size screen to access full site functionality.</Typography>
            </FormControl>
        </div>
    );
}