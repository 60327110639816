import ITeamSeason from '../dto/interfaces/ITeamSeason'
import { ITeam } from '../dto/interfaces/ITeamSeason';

export default class DefaultTeam implements ITeamSeason {
    id: number;
    team_id: number;
    season_id: number;
    division_id: number;
    city: string;
    abbrev: string;
    ulti_analytics_ext_id: number;
    final_standing: number;
    team: ITeam;

    constructor(teamId?: string) {
        this.id = 0;
        this.team_id = 0;
        this.season_id = 0;
        this.division_id = 0;
        this.city = "default";
        this.abbrev = "default";
        this.ulti_analytics_ext_id = 0;
        this.final_standing = 0;
        this.team = { id: 0, name: "default", ext_team_id: teamId ? teamId : "default" }
    }
}
