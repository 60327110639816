import * as React from 'react';
import "../css/base.css"
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import { GridOptions } from 'ag-grid-community';

interface IProps {
    rows: any[],
    columns: any
}

export default function CustomGrid({ rows, columns }: IProps) {

    const gridOptions: GridOptions = {
        tooltipShowDelay: 500,
        columnDefs: columns
    }

    return (
        <div className="ag-theme-balham grid" >
            <AgGridReact
                gridOptions={gridOptions}
                rowData={rows}
            />
        </div>
    );
}
