import { Button, FormGroup, Typography } from "@mui/material";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import api from '../api_urls';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useRecoilValue } from "recoil";
import { headerObject } from "../state/store/Selectors";


// button
// on click, send API update requests
// display succes or failure
export default function UpdateVideoCatlog() {
    const colors = {
        success: "green",
        failure: "red",
        default: "grey"
    }
    const defaultErrorText = "";
    const header = useRecoilValue(headerObject);
    const [folderIconColor, setFolderIconColor] = useState(colors.default);
    const [videoIconColor, setVideoIconColor] = useState(colors.default);
    const [videoErrorText, setVideoErrorText] = useState(defaultErrorText);
    const [folderErrorText, setFolderErrorText] = useState(defaultErrorText);
    const [disableFolders, setDisableFolders] = useState(false);
    const [disableVideos, setDisableVideos] = useState(false);

    async function updateFolders() {
        setDisableFolders(true);
        try {
            await axios.get(`${api.ROOT}${api.ADMIN}${api.UPDATE_FOLDERS}`, header)
            setFolderIconColor(colors.success);
            setFolderErrorText(defaultErrorText)
        } catch (error: AxiosError | any) {
            setFolderIconColor(colors.failure)
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    setFolderErrorText(`Error: ${error.response.status}`)
                } else if (error.request) {
                    setFolderErrorText("The client never received a response, and the request was never left")
                } else {
                    setFolderErrorText("Unknown error")
                }
            } else {
                setFolderErrorText("Unknown error not of type AxiosError")
            }

        } finally {
            setDisableFolders(false)
        }
    }

    async function updateVideos() {
        setDisableVideos(true);
        try {
            await axios.get(`${api.ROOT}${api.ADMIN}${api.UPDATE_VIDEOS}`, header)
            setVideoIconColor(colors.success);
            setVideoErrorText(defaultErrorText);
        } catch (error: AxiosError | any) {
            setVideoIconColor(colors.failure)
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    setVideoErrorText(`Error: ${error.response.status}`)
                } else if (error.request) {
                    setVideoErrorText("The client never received a response, and the request was never left")
                } else {
                    setVideoErrorText("Unknown error")
                }
            } else {
                setVideoErrorText("Unknown error not of type AxiosError")
            }

        } finally {
            setDisableVideos(false)
        }
    }

    return (

        <FormGroup sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h3" sx={{ marginLeft: "10px", marginRight: "10px", textAlign: "center" }}>Available actions</Typography>
            <Button
                onClick={updateFolders}
                startIcon={<CheckCircleIcon sx={{ color: folderIconColor }} />}
                disabled={disableFolders}
            >
                Refresh folders
            </Button>
            {folderErrorText && <Typography>{`${folderErrorText}`}</Typography>}
            <Button
                onClick={updateVideos}
                startIcon={<CheckCircleIcon sx={{ color: videoIconColor }} />}
                disabled={disableVideos}
            >
                Refresh videos
            </Button>
            {videoErrorText && <Typography>{`${videoErrorText}`}</Typography>}

        </FormGroup>
    )
}