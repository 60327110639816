import { ReactElement } from 'react';
import UnauthorizedView from '../views/Unauthorized/UnauthorizedView';
import { useAuth0 } from '@auth0/auth0-react';
import { isAuthorized } from '../state/store/Selectors';
import { useRecoilValue } from 'recoil';

export default function Protect(component: ReactElement): ReactElement {
    const { isAuthenticated } = useAuth0();
    const authorized = useRecoilValue(isAuthorized)
    if (isAuthenticated && authorized) {
        return component;
    }
    else {
        return <UnauthorizedView />;
    }
}

// I like above as I find it a more readable solution in App.tsx
// Below is probably a more "proper" solution

// Below is a functional component that returns a component
// above is a function that returns a component

//////////////////////////////////

// type props = {
//     e: ReactElement
// }

// export default function Protect({ e }: props): ReactElement {
//     const { isAuthenticated } = useAuth0();

//     if (isAuthenticated) {
//         return e;
//     }
//     else {
//         return <UnauthenticatedView />;
//     }

// }

