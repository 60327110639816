import { ColDef, ValueGetterParams } from 'ag-grid-community';
import ITeamOnOffColumns from './interfaces/ITeamOnOffColumns';

const name_width = 125;
const data_width = 60;

const default_params: ColDef = {
    width: data_width,
    type: 'numericColumn',
    sortable: true
}

const paramMapper = (params: ValueGetterParams): ITeamOnOffColumns => {
    return {
        playerId: params.data.playerId,
        oppA: params.data.oppA,
        opsA: params.data.opsA,
        oppO: params.data.oppO,
        opsO: params.data.opsO,
        oppD: params.data.oppD,
        opsD: params.data.opsD,
        dppA: params.data.dppA,
        dpsA: params.data.dpsA,
        dppO: params.data.dppO,
        dpsO: params.data.dpsO,
        dppD: params.data.dppD,
        dpsD: params.data.dpsD,
    } as ITeamOnOffColumns;
}

const oRateT = (params: ValueGetterParams) => {
    const line = paramMapper(params);
    if (line.oppA === 0) {
        return 0;
    }
    return (line.opsA / line.oppA).toFixed(3);
}

const oRateO = (params: ValueGetterParams) => {
    const line = paramMapper(params);
    if (line.oppO === 0) {
        return 0;
    }
    return (line.opsO / line.oppO).toFixed(3);
}

const oRateD = (params: ValueGetterParams) => {
    const line = paramMapper(params);
    if (line.oppD === 0) {
        return 0;
    }
    return (line.opsD / line.oppD).toFixed(3);
}

const dRateT = (params: ValueGetterParams) => {
    const line = paramMapper(params);
    if (line.dppA === 0) {
        return 0;
    }
    return (1 - (line.dpsA / line.dppA)).toFixed(3);
}

const dRateO = (params: ValueGetterParams) => {
    const line = paramMapper(params);
    if (line.dppO === 0) {
        return 0;
    }
    return (1 - (line.dpsO / line.dppO)).toFixed(3);
}

const dRateD = (params: ValueGetterParams) => {
    const line = paramMapper(params);
    if (line.dppD === 0) {
        return 0;
    }
    return (1 - (line.dpsD / line.dppD)).toFixed(3);
}

export const TeamOnOffColumnsAG: ColDef[] = [
    {
        field: 'playerId',
        headerName: 'Player',
        width: name_width,
        pinned: 'left'
    }, {
        ...default_params,
        field: 'oppA',
        headerName: 'opp',
        headerTooltip: 'Total offensive possessions',
        headerClass: 'header-yellow'
    }, {
        ...default_params,
        field: 'opsA',
        headerName: 'ops',
        headerTooltip: 'Total offensive possession goals for',
        headerClass: 'header-yellow'
    }, {
        ...default_params,
        headerName: "O%",
        headerTooltip: "Total offensive possession rate",
        valueGetter: oRateT,
        headerClass: 'header-yellow'
    }, {
        ...default_params,
        field: 'oppO',
        headerName: 'oppO',
        headerTooltip: 'Offensive possessions on offensive points',
        headerClass: 'header-green'
    }, {
        ...default_params,
        field: 'opsO',
        headerName: 'opsO',
        headerTooltip: 'Possession goals for on offensive points',
        headerClass: 'header-green'
    }, {
        ...default_params,
        headerName: "O%O",
        headerTooltip: "Offensive possession rate on offensive points",
        valueGetter: oRateO,
        headerClass: 'header-green'
    }, {
        ...default_params,
        field: 'oppD',
        headerName: 'oppD',
        headerTooltip: 'Offensive possessions on defensive points',
        headerClass: 'header-blue'
    }, {
        ...default_params,
        field: 'opsD',
        headerName: 'opsD',
        headerTooltip: 'Possession goals for on defensive points',
        headerClass: 'header-blue'
    }, {
        ...default_params,
        headerName: "O%D",
        headerTooltip: "Offensive possession rate on defensive points",
        valueGetter: oRateD,
        headerClass: 'header-blue'
    }, {
        ...default_params,
        field: 'dppA',
        headerName: 'dpp',
        headerTooltip: 'Total defensive possessions',
        headerClass: 'header-yellow'
    }, {
        ...default_params,
        field: 'dpsA',
        headerName: 'dps',
        headerTooltip: 'Total defensive possession goals against',
        headerClass: 'header-yellow'
    }, {
        ...default_params,
        headerName: "D%",
        headerTooltip: "Total defensive possession success rate",
        valueGetter: dRateT,
        headerClass: 'header-yellow'
    }, {
        ...default_params,
        field: 'dppO',
        headerName: 'dppO',
        headerTooltip: 'Defensive possessions on offensive points',
        headerClass: 'header-green'
    }, {
        ...default_params,
        field: 'dpsO',
        headerName: 'dpsO',
        headerTooltip: 'Possession goals against on offensive points',
        headerClass: 'header-green'
    }, {
        ...default_params,
        headerName: "D%O",
        headerTooltip: "Defensive possession success rate on offensive points",
        valueGetter: dRateO,
        headerClass: 'header-green'
    }, {
        ...default_params,
        field: 'dppD',
        headerName: 'dppD',
        headerTooltip: 'Defensive possessions on defensive points',
        headerClass: 'header-blue'
    }, {
        ...default_params,
        field: 'dpsD',
        headerName: 'dpsD',
        headerTooltip: 'Possession goals against on defensive points',
        headerClass: 'header-blue'
    }, {
        ...default_params,
        headerName: "D%D",
        headerTooltip: "Defensive possession success rate on defensive points",
        valueGetter: dRateD,
        headerClass: 'header-blue'
    },
]