import React from 'react';
import '../css/base.css';
import { Input, Slider, TextField } from '@mui/material';


interface IProps {
    low: number;
    high: number;
    min: number;
    max: number;
    setLow: (e: number) => void;
    setHigh: (e: number) => void;
    track: (false | "normal" | "inverted" | undefined);

}

export default function FieldAreaSlider({ low, high, min, max, setLow, setHigh, track }: IProps) {

    const handleBlurLow = () => {
        if (low < min) {
            setLow(min);
        } else if (low > max) {
            setLow(max)
        }
    }

    const handleBlurHigh = () => {
        if (high < min) {
            setHigh(min);
        } else if (high > max) {
            setHigh(max)
        }
    }

    const handleLow = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLow(Number(event.target.value));
    };

    const handleHigh = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHigh(Number(event.target.value));
    };

    const handleChange = (event: Event, newValues: number | number[], activeThumb: number) => {
        const asString = newValues.toString();
        const stringArray = asString.split(",");
        setLow(Math.min(...(stringArray.map(Number))));
        setHigh(Math.max(...(stringArray.map(Number))));
    }

    return (
        <div className='sliderFilter'>
            <Slider
                sx={{
                    marginRight: "15px",
                    marginLeft: "5px"
                }}
                value={[low, high]}
                min={min}
                max={max}
                onChange={handleChange}
                track={track}
            />
            <TextField
                sx={{
                    marginLeft: "5px",
                    width: "55%"
                }}
                value={low}
                size="small"
                onChange={handleLow}
                onBlur={handleBlurLow}
                inputProps={{
                    type: 'number',
                }}
            />
            <TextField
                sx={{
                    marginLeft: "5px",
                    width: "55%"
                }}
                value={high}
                size="small"
                onChange={handleHigh}
                onBlur={handleBlurHigh}
                inputProps={{
                    type: 'number',
                }}
            />
        </div>
    );
}