
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import GameStats from './views/GameStats/GameStats'
import TeamStats from './views/TeamStats/TeamStats'
import PlayerStats from './views/PlayerStats/PlayerStats'
import { themeOptions } from './theme/theme'
import { ThemeProvider } from '@emotion/react';
import { createTheme, Typography } from '@mui/material';
import protect from './utilities/Protect';
import protectAdmin from './utilities/ProtectAdmin';
import { useAuth0 } from '@auth0/auth0-react';
import UnauthenticatedView from './views/UnauthenticatedView';
import WelcomePage from './views/WelcomePage/WelcomePage';
import Video from './views/Video/Video';
import Nav from './components/Nav';
import RickRoll from './views/Rickroll';
import Admin from "./views/Admin/Admin"

const theme = createTheme(themeOptions);

function App() {
    const { isAuthenticated } = useAuth0();

    const d = new Date();
    const liveTime = 1650121200;
    const isMaintenance = () => {
        const now = d.getTime() / 1000;
        return now < liveTime;
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                {
                    isMaintenance() && (
                        <Typography>Down for maintenance until 4/16/2022 10AM CST</Typography>
                    )
                }

                {!isMaintenance() && isAuthenticated && (
                    <>
                        <Nav />
                        <Routes>
                            <Route path='' element={<WelcomePage />} />
                            <Route path='/games' element={protect(<GameStats />)} >
                                <Route path=":requestedGame" element={protect(<GameStats />)} />
                            </Route>
                            <Route path="/teams" element={protect(<TeamStats />)} >
                                <Route path=":selectedTeam" element={protect(<TeamStats />)} />
                            </Route>
                            <Route path="/players" element={protect(<PlayerStats />)} >
                                <Route path=":playerId" element={protect(<PlayerStats />)} />
                            </Route>
                            <Route path="/videos" element={protect(<Video />)} >
                                <Route path=":videoId" element={protect(<Video />)} />
                            </Route>
                            <Route path="/admin" element={protectAdmin(<Admin />)} />
                            <Route path="/opt-out" element={<RickRoll />} />
                        </Routes>
                    </>)}

                {!isMaintenance() && !isAuthenticated && <UnauthenticatedView />}
            </div>
        </ThemeProvider>
    );
}

export default App;
