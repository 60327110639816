import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { maxWidth } from '../../constants/MobilePixelWidth';
import IVideo from '../../dto/interfaces/vimeo/IVideo';
import Desktop from './Desktop'
import Mobile from './Mobile'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { DataEntity } from '../../dto/interfaces/vimeo/IVimeoVideos';
import api from '../../api_urls';
import { headerObject } from '../../state/store/Selectors';
import { useRecoilValue } from 'recoil';

export default function Video() {
    const isMobile = useMediaQuery(`(max-width:${maxWidth}px)`);
    const header = useRecoilValue(headerObject);
    const [video, setVideo] = useState<IVideo>({} as IVideo)
    const { videoId } = useParams();

    useEffect(() => {
        if (videoId) {
            axios.get<DataEntity>(`${api.ROOT}${api.EXTERNAL}${api.VIMEO}${api.VIDEOS}/${videoId}`, header)
                .then((res) => {
                    const video = res.data;
                    const parentUri = video.parent_folder?.uri;
                    const isPublic = video.privacy.view === "password";

                    const videoToLoad = {
                        name: video.name,
                        uri: video.player_embed_url,
                        parentUri: parentUri,
                        duration: video.duration,
                        hideFromWebsite: isPublic,
                        description: video.description || ""
                    } as IVideo

                    setVideo(videoToLoad)
                })
        }
    }, [videoId])

    const descriptionLines = (s: string): string[] => {
        if (s) {
            const lines = s.split("\n")
            return lines;
        }
        return []
    }

    return (
        <>
            {isMobile ? <Mobile video={video} setVideo={setVideo} descriptionLines={descriptionLines} /> : <Desktop video={video} setVideo={setVideo} descriptionLines={descriptionLines} />}
        </>
    );
}
