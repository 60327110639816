
import React from 'react';
import { Button, ButtonGroup, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';

interface IProps {
    selectAll: () => void;
    selectNone: () => void;
    selected: string[];
    available: string[];
    renderFcn: () => string | string[];
    changeFcn: (event: SelectChangeEvent<string[]>) => void;
    label: string;
    marginTop?: number;
}

export default function CategoricalSelector({ selectAll, selectNone, label, selected, available, renderFcn, changeFcn, marginTop = 0 }: IProps) {
    return (
        <div className='filter'>
            <FormControl sx={{ paddingTop: "0px", width: "100%", marginTop: `${marginTop}px` }} >
                <InputLabel>{label}</InputLabel>
                <Select
                    multiple
                    value={selected}
                    renderValue={renderFcn}
                    onChange={changeFcn}
                    input={<OutlinedInput label={`${label}`} />}
                >
                    {available.map((item) => (
                        <MenuItem
                            key={item}
                            value={item}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
                <ButtonGroup sx={{ paddingTop: "10px", width: "100%" }}>
                    <Button
                        sx={{ width: "50%" }}
                        onClick={selectAll}
                    >
                        Select All
                    </Button>
                    <Button
                        sx={{ width: "50%" }}
                        onClick={selectNone}
                    >
                        Select None
                    </Button>
                </ButtonGroup>
            </FormControl>
        </div>
    );
}
