import IPlayer from '../dto/interfaces/IPlayer'

export default class DefaultPlayer implements IPlayer {
    id: string;
    first_name: string;
    last_name: string;
    ext_player_id: string;

    constructor(playerId?: string) {
        this.id = "default";
        this.first_name = "default";
        this.last_name = "default";
        this.ext_player_id = playerId ? playerId : "default";
    }
}
