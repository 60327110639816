import React from 'react';
import Auth0Login from '../components/Auth0Login';


export default function UnauthenticatedView() {

    return (
        <div className="loginScreen">
            <Auth0Login />
        </div>
    );
}
