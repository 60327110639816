import ISeasonIdMapping from "../dto/interfaces/ISeasonIdMapping";

export const seasonIdMapping: ISeasonIdMapping[] = [
    {
        id: '12',
        year: '2021'
    },
    {
        id: '13',
        year: '2022'
    },
    {
        id: '14',
        year: '2023'
    },
    {
        id: '15',
        year: '2024'
    }
]