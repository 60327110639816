import React, { useEffect, useState } from 'react';
import CustomGrid from '../../components/CustomGrid'
import axios from 'axios';
import api from '../../api_urls';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import IBaseStatsColumns from '../../dto/interfaces/IBaseStatsColumns';
import defaultString from '../../constants/DefaultString';
import { useRecoilValue } from 'recoil';
import { headerObject } from '../../state/store/Selectors';
import { alphabeticalSort } from '../../utilities/Sorters';
import { BaseStatsColumns } from '../../dto/BaseStatsColumns';

interface IHeaderInfo {
    home: string;
    away: string;
    homeScore: number;
    awayScore: number;
    year: number;
    week: string;
}

export default function GameStats() {
    const { requestedGame } = useParams();
    const [tableData, setTableData] = useState<IBaseStatsColumns[]>([]);
    const [gameList, setGameList] = useState<string[]>([""]);
    const [game, setGame] = useState(requestedGame ? requestedGame : defaultString);
    const [gameHeader, setGameHeader] = useState<IHeaderInfo>();
    const header = useRecoilValue(headerObject);


    useEffect(() => {
        axios.get<string[]>(`${api.ROOT}${api.ALLGAMES}`, header)
            .then(res => {
                const gamesResponse = res.data;
                setGameList(alphabeticalSort(gamesResponse));
            })
    }, [])

    useEffect(() => {
        if (game) {
            Promise.all([
                getGameHeader().then(setGameHeader),
                getData().then(setTableData)
            ]);
            // change URL to match new selection
            if (game !== defaultString) {
                window.history.pushState({}, "", `/games/${game}`);
            }
        }
    }, [game])

    const handleGameChange = (event: SelectChangeEvent) => {
        setGame(event.target.value as string);
    }

    async function getData(): Promise<any> {
        const res = await axios.get<IBaseStatsColumns[]>(`${api.ROOT}${api.GAMES}/${game}`, header);
        return res.data;
    }

    async function getGameHeader(): Promise<IHeaderInfo> {
        const info = await axios.get<IHeaderInfo>(`${api.ROOT}${api.GAMES}${api.GAME_HEADER_INFO}/${game}`, header);
        return info.data;
    }

    function formatGameHeader(info: IHeaderInfo): string {
        if (info) {
            return `${info.away} (${info.awayScore}) @ ${info.home} (${info.homeScore})`;
        }
        return "";
    }

    function formatDate(info: IHeaderInfo): string {
        return `${info.year} ${info.week}`;
    }

    return (
        <div className="basicViewBody">
            <Select
                value={game}
                onChange={handleGameChange}
            >
                <MenuItem key={defaultString} value={defaultString}>Select a game..</MenuItem>
                {gameList.map((gameFromList) => {
                    return (<MenuItem key={gameFromList} value={gameFromList}>{gameFromList}</MenuItem>)
                })}
            </Select>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }} >
                {gameHeader &&
                    <>
                        <Box sx={{ fontSize: 30, fontWeight: 'bold', }}>{formatGameHeader(gameHeader)}</Box>
                        <Box sx={{ fontSize: 20, }}>{formatDate(gameHeader)}</Box>
                    </>}
            </Box>
            <CustomGrid rows={tableData} columns={BaseStatsColumns} />
        </div>
    );
}
