import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import GroupsIcon from '@mui/icons-material/Groups';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import VideocamIcon from '@mui/icons-material/Videocam';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export interface IRouteInfo {
    name: string;
    path: string;
    icon: any;
}

export const ROUTES: IRouteInfo[] = [
    { name: "Teams", icon: <GroupsIcon />, path: "/teams" },
    { name: "Games", icon: <SportsScoreIcon />, path: "/games" },
    { name: "Players", icon: <DirectionsRunIcon />, path: "/players" },
    { name: "Videos", icon: <VideocamIcon />, path: "/videos" }
]

export const ROUTES_ADMIN: IRouteInfo[] = [
    { name: "Admin", icon: <AdminPanelSettingsIcon />, path: "/admin" }
]