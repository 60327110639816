
import React, { KeyboardEvent } from 'react';
import { FormControlLabel, FormGroup, TextField, Tooltip, Typography } from '@mui/material';
import IIntPickerDef from './interfaces/IIntPickerDef';

interface IProps {
    values: IIntPickerDef[];
    handleBlur: () => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    marginSize: number;
}

export default function QuadIntPicker({ values, marginSize, handleBlur, handleChange }: IProps) {
    const styles = { width: '60%', marginLeft: `${marginSize}px`, marginRight: `${marginSize}px`, marginBottom: `${marginSize / 2}px` }

    const enterPress = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            handleBlur();
        }
    }

    return (
        <FormGroup row={true}>
            {values.map((value: IIntPickerDef) => {
                return (
                    <FormControlLabel control={
                        <TextField
                            value={value.value}
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="number"
                            id={value.id}
                            sx={{ width: "75px", marginRight: "10px" }}
                            onKeyDown={enterPress}
                        />
                    }
                        label={value.label}
                        sx={{
                            marginBottom: "10px",
                            marginLeft: "0px"
                        }}
                    />
                )
            })}
        </FormGroup>
    )
}
