import { List, ListItemButton, ListItemIcon, ListItemText, Box } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HelpIcon from '@mui/icons-material/Help';

interface IProps {
    handleOpenBug: () => void;
    fontColor: string;
    listStyles: any;
    handleLogout: () => void;
    openFaq: () => void;
}

export default function Auth0Logout({ handleOpenBug, fontColor: fontColor, listStyles, handleLogout, openFaq }: IProps) {

    return (<List sx={listStyles}>
        <Box>
            <ListItemButton
                onClick={() => openFaq()}
                sx={{
                    color: fontColor
                }}>
                <ListItemIcon sx={{ color: fontColor }}>
                    <HelpIcon />
                </ListItemIcon>
                <ListItemText primary="FAQ" />
            </ListItemButton>
        </Box>

        <Box>
            <ListItemButton
                onClick={() => handleOpenBug()}
                sx={{
                    color: fontColor
                }}
            >
                <ListItemIcon sx={{ color: fontColor }}>
                    <ReportProblemIcon />
                </ListItemIcon>
                <ListItemText primary="Report bug" />
            </ListItemButton>
        </Box>

        <Box>
            <ListItemButton
                onClick={() => handleLogout()}
                sx={{
                    color: fontColor
                }}
            >
                <ListItemIcon sx={{ color: fontColor }}>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Log out" />
            </ListItemButton>
        </Box>
    </List>)

}