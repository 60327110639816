import React from 'react'
import { FormControl, Typography } from '@mui/material';

export default function Mobile() {
    return (
        <div id='welcome'>
            <FormControl sx={{ display: "flex", flexDirection: "column", height: "100vh", alignItems: "center" }}>
                <Typography variant="h3" align='center' sx={{ marginBottom: "20px" }}>You are not authorized to view this page</Typography>
                <Typography variant="subtitle1" sx={{ marginBottom: "20px" }}>You will see this error if you are attempting to access this page with a different email than we have on file (the one you used to fill out your preseason interest form)</Typography>
                <Typography variant="subtitle1">Please contact max to update your email</Typography>
            </FormControl>
        </div>
    );
}