export interface IAuth0Config {
    dev: IConfig;
    prod: IConfig;
}

interface IConfig {
    domain: string;
    clientId: string;
    audience: string;
}

const auth0config: IAuth0Config = {
    dev: {
        domain: "dev-yod8stzp.us.auth0.com",
        clientId: "Thd3udSvTW8H2l8AQNVF2AKhjNmHU91b",
        audience: "http://localhost:8080"
    },
    prod: {
        domain: "minnesotawindchill.us.auth0.com",
        clientId: "MAjOXTqEf0isFwBQRPYwCpFnoubXoX9x",
        audience: "https://minnesotawindchill.win"
    }
}

export const config = process.env.NODE_ENV === "development" ? auth0config.dev : auth0config.prod;