
import React from 'react';
import { FormControlLabel, FormGroup, TextField, Tooltip, Typography } from '@mui/material';

interface IProps {
    minutes: number | string;
    seconds: number | string;
    minutesChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    secondsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    minutesBlur: () => void;
    secondsBlur: () => void;
    marginSize: number;
    label: string;
    helptext: string;
}

export default function GameTimeInput({ minutes, seconds, minutesChange, secondsChange, minutesBlur, secondsBlur, marginSize, label, helptext }: IProps) {
    const styles = { width: '60%', marginLeft: `${marginSize}px`, marginRight: `${marginSize}px`, marginBottom: `${marginSize / 2}px` }
    return (
        <FormGroup className='filter' row={true}>
            <Tooltip title={helptext} placement='top'><Typography sx={{ width: "100%" }}> {label} </Typography></Tooltip>
            <FormControlLabel control={
                <TextField
                    value={minutes}
                    size="small"
                    onChange={minutesChange}
                    onBlur={minutesBlur}
                    type="number"
                    sx={styles}
                />
            }
                label="Min"
                sx={{ width: "50%", marginRight: "10px" }}
            />

            <FormControlLabel control={
                <TextField
                    value={seconds}
                    size="small"
                    onChange={secondsChange}
                    onBlur={secondsBlur}
                    type="number"
                    sx={styles}
                />
            }
                label="Max"
                sx={{ width: "50%", marginRight: "0px" }}
            />
        </FormGroup>
    );
}
