interface ITeam {
    team_ext_id: string,
    color: string
}

export const teamColors: ITeam[] = [
    {
        team_ext_id: "hustle",
        color: "#412a74"
    },
    {
        team_ext_id: "sol",
        color: "#e44b25"
    },
    {
        team_ext_id: "glory",
        color: "#a41e21"
    },
    {
        team_ext_id: "union",
        color: "#28bff0"
    },
    {
        team_ext_id: "breeze",
        color: "#b62428"
    },
    {
        team_ext_id: "roughnecks",
        color: "#181542"
    },
    {
        team_ext_id: "mechanix",
        color: "#690202"
    },
    {
        team_ext_id: "alleycats",
        color: "#00703c"
    },
    {
        team_ext_id: "aviators",
        color: "#a3282a"
    },
    {
        team_ext_id: "radicals",
        color: "#aba535"
    },
    {
        team_ext_id: "windchill",
        color: "#707f99"
    },
    {
        team_ext_id: "royal",
        color: "#1c2250"
    },
    {
        team_ext_id: "empire",
        color: "#6abd45"
    },
    {
        team_ext_id: "outlaws",
        color: "#939598"
    },
    {
        team_ext_id: "phoenix",
        color: "#ed4c23"
    },
    {
        team_ext_id: "thunderbirds",
        color: "#febd11"
    },
    {
        team_ext_id: "flyers",
        color: "#6fadd9"
    },
    {
        team_ext_id: "growlers",
        color: "#6d6e71"
    },
    {
        team_ext_id: "spiders",
        color: "#050505"
    },
    {
        team_ext_id: "cascades",
        color: "#145288"
    },
    {
        team_ext_id: "cannons",
        color: "#fce112"
    },
    {
        team_ext_id: "rush",
        color: "#d22336"
    }
]

export const audlColor = "rgba(255, 183, 220, 1)";