import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IFilterablePass from '../dto/interfaces/IFilterablePass';
import { Tooltip, Typography } from '@mui/material';

interface IProps {
    passes: IFilterablePass[];
    activePassId: number;
}

// This whole thing sucks I'd like it to be different but not a priority
export default function SummaryTablePasses({ passes, activePassId }: IProps) {

    const adjCmpPct = () => {
        const n = passes.length;
        const turnovers = turns();
        const droppedPasses = drops();

        return ((n - turnovers - droppedPasses) / (n - droppedPasses)).toFixed(3);
    }

    const drops = () => {
        return passes.filter(pass => pass.result === 'DROP').length;
    }

    const turns = () => {
        return passes.filter(pass => pass.result === 'THROWING_ERROR' || pass.result === 'CALLAHAN').length;
    }

    const assists = () => {
        return passes.filter(pass => pass.result === 'GOAL').length;
    }

    const ydsThrowing = () => {
        const completions = passes.filter(pass => pass.result === 'GOAL' || pass.result === 'CATCH')
        return completions.length > 0 ? completions.map(pass => pass.yardsThrowing).reduce((prv, nxt) => prv + nxt) : 0
    }

    const ydsPerAtt = () => {
        return passes.length > 0 ? (ydsThrowing() / passes.length).toFixed(1) : "N/A"
    }

    const ydsPerTurn = () => {
        const turnovers = turns();
        return turnovers !== 0 ? (ydsThrowing() / turns()).toFixed(1) : "N/A";
    }

    const turnoverYds = () => {
        const turnovers = passes.filter(pass => pass.result === 'THROWING_ERROR' || pass.result === 'CALLAHAN')
        return turnovers.length > 0 ? (turnovers.map(pass => pass.yardsThrowing).reduce((prv, nxt) => prv + nxt) / turns()).toFixed(1) : "N/A"
    }

    const makeRow = (passId: number | undefined) => {
        const usePass = passes.find(x => x.passId === passId);
        return (usePass &&
            <>
                <TableCell>{usePass.throwerId}</TableCell>
                <TableCell>{usePass.targetId}</TableCell>
                <TableCell>{usePass.result}</TableCell>
                <TableCell>{Math.round(usePass.yardsY as number)}</TableCell>
                <TableCell>{Math.round(usePass.yardsX as number)}</TableCell>
                <TableCell>{Math.round(usePass.yardsAir as number)}</TableCell>
                <TableCell>{usePass.quarter}</TableCell>
                <TableCell>{usePass.gameId}</TableCell>
            </>
        );
    }

    return (
        <>
            {passes.length > 0 && (
                <>
                    <Typography sx={{ marginTop: '0px', width: "59%" }} variant="h5"> View Stats </Typography>
                    <TableContainer sx={{ width: '250px' }} component={Paper}>
                        <Table size="small">
                            <TableRow>
                                <TableCell>Total passes</TableCell>
                                <TableCell>{passes.length}</TableCell>
                            </TableRow>
                            <TableRow>
                                <Tooltip title="Cmp % excluding dropped passes" placement='top'>
                                    <TableCell>Adj. cmp %</TableCell>
                                </Tooltip>
                                <TableCell>{adjCmpPct()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Turnovers</TableCell>
                                <TableCell>{turns()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Assists</TableCell>
                                <TableCell>{assists()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Dropped passes</TableCell>
                                <TableCell>{drops()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <Tooltip title="Attack endzone yards omitted" placement='top'>
                                    <TableCell>Throwing Yards</TableCell>
                                </Tooltip>
                                <TableCell>{ydsThrowing().toFixed(0)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <Tooltip title="Throwing yards / total throws" placement='top'>
                                    <TableCell>Throwing Yards/Attempt</TableCell>
                                </Tooltip>
                                <TableCell>{ydsPerAtt()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <Tooltip title="Throwing yards / throwing turnovers" placement='top'>
                                    <TableCell>Throwing Yards/Turn</TableCell>
                                </Tooltip>
                                <TableCell>{ydsPerTurn()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <Tooltip title="Throwing yards gained/lost on turnover throws" placement='top'>
                                    <TableCell>Turnover yards/turn</TableCell>
                                </Tooltip>
                                <TableCell>{turnoverYds()}</TableCell>
                            </TableRow>
                        </Table>
                    </TableContainer>
                </>)
            }

            {passes.length > 0 && <>
                <Typography sx={{ marginTop: '10px', width: "59%" }} variant="h5"> Pass Details </Typography>
                <TableContainer sx={{ width: "100%" }} component={Paper}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <Tooltip title="Thrower" placement='top'><TableCell>Thrower</TableCell></Tooltip>
                                <Tooltip title="Target" placement='top'><TableCell>Target</TableCell></Tooltip>
                                <Tooltip title="Result" placement='top'><TableCell>Result</TableCell></Tooltip>

                                <Tooltip title="Vertical yards" placement='top'><TableCell>YdsY</TableCell></Tooltip>
                                <Tooltip title="Horizontal yards" placement='top'><TableCell>YdsX</TableCell></Tooltip>
                                <Tooltip title="Total distance traveled" placement='top'><TableCell>Dist</TableCell></Tooltip>
                                <Tooltip title="Quarter" placement='top'><TableCell>Quarter</TableCell></Tooltip>
                                <Tooltip title="Game" placement='top'><TableCell>Game</TableCell></Tooltip>
                            </TableRow>

                            <TableRow>
                                {makeRow(activePassId)}
                            </TableRow>
                        </TableBody>
                    </Table>

                </TableContainer>
            </>}
        </>
    );
}