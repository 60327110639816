import React, { useEffect, useState } from 'react'
import ITeamSeason from '../../dto/interfaces/ITeamSeason';
import api from '../../api_urls';
import axios from 'axios';
import IXy from '../../dto/interfaces/IXy';
import { AgChartOptions, AgSeriesTooltipRendererParams } from 'ag-charts-community';
import { teamColors, audlColor } from '../../theme/TeamColors';
import MultiIntPicker from '../MultiIntPicker';
import IIntPickerDef from '../interfaces/IIntPickerDef';
import { useRecoilValue } from 'recoil';
import { selectedSeasonId } from '../../state/store/Atoms';
import { headerObject } from '../../state/store/Selectors';
import PointValueSingleChart from './PointValueSingleChart';
import capitalize from '../../utilities/Capitalize'
import { FormControl, FormControlLabel, Switch } from '@mui/material';
import SelectMultipleTeams from '../SelectMultipleTeams';

interface IProps {
    team: ITeamSeason;
    teamList: ITeamSeason[];
}

interface IQuarters {
    q1: ISingleQuarter;
    q2: ISingleQuarter;
    q3: ISingleQuarter;
    q4: ISingleQuarter;
}

interface ISingleQuarter {
    show: boolean;
    data: IXy[];
    id: string;
}

export interface ITeamData {
    data: IXy[];
    id: number;
}

export default function PointValueByTime({ team, teamList }: IProps) {
    const defaultYMax = 0.7;
    const defaultYMin = 0;
    const [leftX, setLeftX] = useState(720);
    const [leagueData, setLeagueData] = useState<IXy[]>([]);
    const [offData, setOffData] = useState<ITeamData[]>([]);
    const [defData, setDefData] = useState<ITeamData[]>([]);
    const [quarterData, setQuarterData] = useState<IQuarters>({} as IQuarters);
    const [byQuarter, setByQuarter] = useState(false);
    const [showAudl, setShowAudl] = useState(true);
    const [selectedTeams, setSelectedTeams] = useState<number[]>([team.id]);
    const seasonId = useRecoilValue(selectedSeasonId);
    const header = useRecoilValue(headerObject);
    const title = "Expected value of point for receiving team by time remaining"
    const subtitle = `${capitalize(team.team.ext_team_id)}`
    const offenseSubtitle = `${subtitle} receiving (higher expected value better)`
    const defenseSubtitle = `${subtitle} pulling (lower expected value better)`
    const audlTitleByQuarter = "All"
    const audlTitleByTeam = "AUDL"
    const audlIdentifier = "AUDL";


    // const tempOff = [] as IXy[][]
    // for (const team in selectedTeams) {
    //     getDataAllQuarters(Number(team)).then((returnedData) => {
    //         tempOff.splice(Number(team), 0, returnedData);
    //     })
    // }
    useEffect(() => {
        getDataAllQuarters(-1).then((returnedData) => {
            setLeagueData(returnedData);
        })

        const oTemp = [] as ITeamData[];
        var i = 1;
        for (const team of selectedTeams) {
            getDataAllQuarters(team).then((returnedData) => {
                oTemp.push({ id: team, data: returnedData });
                if (i === selectedTeams.length) {
                    setOffData(oTemp);
                }
                i++
            });
        }

        const dTemp = [] as ITeamData[];
        var j = 1;
        for (const team of selectedTeams) {
            getDataAllQuarters(team, false).then((returnedData) => {
                dTemp.push({ id: team, data: returnedData });
                if (j === selectedTeams.length) {
                    setDefData(dTemp);
                }
                j++
            });
        }

    }, [selectedTeams])

    useEffect(() => {
        var quarterD = {} as IQuarters
        Promise.all([
            getDataOneQuarter(-1, 1).then((returnedData) => {
                quarterD = { ...quarterD, q1: { data: returnedData, show: showData(1), id: "q1" } }
            }),
            getDataOneQuarter(-1, 2).then((returnedData) => {
                quarterD = { ...quarterD, q2: { data: returnedData, show: showData(2), id: "q2" } }
            }),
            getDataOneQuarter(-1, 3).then((returnedData) => {
                quarterD = { ...quarterD, q3: { data: returnedData, show: showData(3), id: "q3" } }
            }),
            getDataOneQuarter(-1, 4).then((returnedData) => {
                quarterD = { ...quarterD, q4: { data: returnedData, show: showData(4), id: "q4" } }
            }),
        ]).then(() => {
            setQuarterData(quarterD);
        })
    }, [])

    useEffect(() => {
        setSelectedTeams([team.id])
    }, [team])

    const showData = (quarter: number) => {
        switch (quarter) {
            case 1:
                if (quarterData?.q1?.show === false) {
                    return false;
                }
                break;
            case 2:
                if (quarterData?.q2?.show === false) {
                    return false;
                }
                break;
            case 3:
                if (quarterData?.q3?.show === false) {
                    return false;
                }
                break;
            case 4:
                if (quarterData?.q4?.show === false) {
                    return false;
                }
                break;
        }
        return true;
    }

    async function getDataAllQuarters(teamId: number, offense = true) {
        const res = await axios.post<IXy[]>(`${api.ROOT}${api.TEAMS}${api.POINT_VALUE}`, { team: teamId, seasonId: seasonId, offense: offense, byQuarter: false }, header)
        return res.data;
    }

    async function getDataOneQuarter(teamId: number, quarter: number) {
        const res = await axios.post<IXy[]>(`${api.ROOT}${api.TEAMS}${api.POINT_VALUE}`, { team: teamId, seasonId: seasonId, byQuarter: true, quarters: [quarter] }, header)
        return res.data;
    }

    const getXAxisSeries = () => {
        return [{ x: 0, y: 0 }, { x: leftX, y: 0 }]
    }

    const getColor = (tm: ITeamSeason) => {
        return teamColors.find(x => x.team_ext_id === tm.team.ext_team_id)?.color
    }

    const setYMinMany = (data: ITeamData[], leagueData: IXy[]) => {
        var currentMin = defaultYMin;
        for (const datum of data) {
            currentMin = Math.min(currentMin, setYMin(datum.data, leagueData));
        }
        return currentMin;
    }

    const setYMaxMany = (data: ITeamData[], leagueData: IXy[]) => {
        var currentMax = defaultYMax;
        for (const datum of data) {
            currentMax = Math.max(currentMax, setYMax(datum.data, leagueData));
        }
        return currentMax;
    }

    const setYMax = (teamData: IXy[], audlData: IXy[]) => {
        const teamMax = Math.max(...teamData.map(value => value.y)) > defaultYMax ? Math.max(...teamData.map(value => value.y)) : defaultYMax;
        const leagueMax = Math.max(...audlData.map(value => value.y)) > defaultYMax ? Math.max(...audlData.map(value => value.y)) : defaultYMax;
        return Math.max(teamMax, leagueMax);
    }

    const setYMin = (teamData: IXy[], audlData: IXy[]) => {
        const teamMin = Math.min(...teamData.map(value => value.y)) < defaultYMin ? Math.min(...teamData.map(value => value.y)) : defaultYMin;
        const leagueMin = Math.min(...audlData.map(value => value.y)) < defaultYMin ? Math.min(...audlData.map(value => value.y)) : defaultYMin;
        return Math.min(teamMin, leagueMin);
    }

    const getQuarterColors = (quarter: number) => {
        const q1 = "red";
        const q2 = "orange";
        const q3 = "green";
        const q4 = "blue";

        switch (quarter) {
            case 1:
                return q1;
            case 2:
                return q2;
            case 3:
                return q3;
            case 4:
                return q4;
            default:
                return "black";
        }
    }

    const buildQuarterSeries = () => {
        const quarterArray = [quarterData?.q1?.data, quarterData?.q2?.data, quarterData?.q3?.data, quarterData?.q4?.data];

        const quarterSeries = quarterArray.map((series) => {
            const quarter = quarterArray.findIndex(x => x === series) + 1;
            if (showData(quarter)) {
                return (
                    {
                        data: series,
                        xKey: 'x',
                        yKey: 'y',
                        title: `Q${quarter}`,
                        stroke: getQuarterColors(quarter),
                        tooltip: {
                            renderer: function (params: AgSeriesTooltipRendererParams) {
                                return {
                                    content: `${params.datum.y.toFixed(2)}% time=${params.datum.x} N=${params.datum.n}`
                                }
                            }
                        },
                        marker: {
                            fill: getQuarterColors(quarter),
                            stroke: getQuarterColors(quarter)
                        },
                    }
                )
            } else {
                return {};
            }
        })
        quarterSeries.splice(0, 0, buildSeasonSeriesAUDL(audlTitleByQuarter));

        return quarterSeries;
    }

    const buildSeasonSeriesAUDL = (title: string) => {

        return showAudl ? (
            {
                data: leagueData,
                title: title,
                xKey: 'x',
                yKey: 'y',
                stroke: audlColor,
                showInLegend: true,
                tooltip: {
                    renderer: function (params: AgSeriesTooltipRendererParams) {
                        return {
                            content: `${params.datum.y.toFixed(2)}% time=${params.datum.x} N=${params.datum.n}`
                        }
                    }
                },
                marker: {
                    fill: audlColor,
                    stroke: audlColor
                }
            }
        ) : {};
    }

    const buildIndividualTeam = (data: ITeamData) => {
        const teamOrUndefined = teamList.find(t => t.id === data.id);
        const buildTeam = teamOrUndefined ? teamOrUndefined : {} as ITeamSeason;
        return {
            data: data.data,
            title: `${capitalize(buildTeam.team.ext_team_id)}`,
            xKey: 'x',
            yKey: 'y',
            stroke: getColor(buildTeam),
            showInLegend: true,
            tooltip: {
                renderer: function (params: AgSeriesTooltipRendererParams) {
                    return {
                        content: `${params.datum.y.toFixed(2)}% time=${params.datum.x} N=${params.datum.n}`
                    }
                }
            },
            marker: {
                fill: getColor(buildTeam),
                stroke: getColor(buildTeam)
            },
        }
    }

    const buildTeamSeries = (data: ITeamData[]) => {
        const series = []
        series.push({
            data: setYMinMany(data, leagueData) < 0 ? getXAxisSeries() : [],
            xKey: 'x',
            yKey: 'y',
            stroke: "black",
            showInLegend: false,
            marker: {
                enabled: false
            }
        })

        for (const datum of data) {
            series.push(buildIndividualTeam(datum));
        }

        series.push(buildSeasonSeriesAUDL(audlTitleByTeam));

        return series
    }

    const buildOptions = (data: ITeamData[], subtitle: string): AgChartOptions => {

        return {
            autoSize: true,
            legend: {
                enabled: true
            },
            title: {
                text: title,

            },
            subtitle: {
                text: subtitle,
            },
            series: byQuarter ? buildQuarterSeries() : buildTeamSeries(data),
            axes: [
                {
                    type: 'number',
                    position: 'bottom',
                    min: leftX === 0 ? 720 : leftX,
                    max: 0,
                    tick: {
                        count: data[0]?.data?.length
                    },
                    title: {
                        text: 'Time remaining at pull (s)',
                        enabled: true
                    }
                },
                {
                    type: 'number',
                    position: 'left',
                    min: setYMinMany(data, leagueData),
                    max: setYMaxMany(data, leagueData),
                    title: {
                        text: 'Expected value',
                        enabled: true
                    }
                }
            ]
        }
    }

    const handleBlur = () => {
        if (leftX === 0) {
            setLeftX(720);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        var val = '';
        if (event.target.value !== val) {
            val = event.target.value;
        }
        switch (event.target.id) {
            case "maxTime":
                if (Number(val) < 0 || Number(val) > 720) {
                    setLeftX(720);
                } else {
                    setLeftX(Number(val));
                }
                break;
        }
    }

    const handleByQuarterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setByQuarter(event.target.checked);
    };

    const handleQuarterSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        const quarter = event.target.name;
        switch (quarter) {
            case audlIdentifier:
                setShowAudl(!showAudl);
                break;
            case "q1":
                setQuarterData({ ...quarterData, q1: changeSelected(quarterData.q1) })
                break;
            case "q2":
                setQuarterData({ ...quarterData, q2: changeSelected(quarterData.q2) })
                break;
            case "q3":
                setQuarterData({ ...quarterData, q3: changeSelected(quarterData.q3) })
                break;
            case "q4":
                setQuarterData({ ...quarterData, q4: changeSelected(quarterData.q4) })
                break;
        }
    }

    const changeSelected = (quarter: ISingleQuarter) => {
        const shown = quarter.show;
        return { ...quarter, show: !shown }
    }

    const maxTime: IIntPickerDef = {
        value: leftX,
        label: "Max time in quarter",
        id: "maxTime"
    }

    const pickerValues: IIntPickerDef[] = [
        maxTime
    ]

    const audlDataSwitch =
        <FormControlLabel
            name={audlIdentifier}
            control={
                <Switch checked={showAudl} onChange={handleQuarterSelection} />
            }
            label={byQuarter ? audlTitleByQuarter : "Show AUDL"}
        />

    return (
        <div className='charts'>

            <FormControl sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                <FormControlLabel
                    control={
                        <Switch checked={byQuarter} onChange={handleByQuarterChange} />
                    }
                    label="View data for all AUDL by quarter"

                />
                {!byQuarter && audlDataSwitch}
                <MultiIntPicker values={pickerValues} handleChange={handleChange} handleBlur={handleBlur} marginSize={10}></MultiIntPicker>
            </FormControl>

            {!byQuarter &&
                <>
                    <SelectMultipleTeams team={team} selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams} teamList2={teamList} />
                    <PointValueSingleChart buildOptions={buildOptions} title={offenseSubtitle} data={offData} />
                    <PointValueSingleChart buildOptions={buildOptions} title={defenseSubtitle} data={defData} />
                </>}
            {byQuarter &&
                <>
                    <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
                        {audlDataSwitch}
                        {[quarterData.q1, quarterData.q2, quarterData.q3, quarterData.q4].map((quarter) => {
                            return (
                                <FormControlLabel
                                    name={quarter?.id}
                                    control={
                                        <Switch checked={quarter?.show} onChange={handleQuarterSelection} />
                                    }
                                    label={capitalize(quarter?.id)}
                                />
                            )
                        })}

                    </FormControl>
                    <PointValueSingleChart buildOptions={buildOptions} title={offenseSubtitle} data={offData} />
                </>
            }

        </div>
    )
}