import { FormControl, Typography } from '@mui/material'
import React from 'react'

export default function MobileNotSupported() {
    return (
        <div id='welcome'>
            <FormControl sx={{ display: "flex", flexDirection: "column", height: "100vh", alignItems: "center", marginTop: "10px" }}>
                <img src="https://upload.wikimedia.org/wikipedia/commons/3/32/Minnesota_Wind_Chill_Logo.jpg" height="100" alt="wind chill logo"></img>
                <Typography variant="h5" align="center" sx={{ marginBottom: "20px", marginTop: "20px" }}>Mobile not supported</Typography>
                <Typography variant="subtitle1">Due to screen size limitations, viewing this page on mobile devices is not yet availble. Please try again on a device with a larger screen!</Typography>
            </FormControl>
        </div>
    )
}