import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import CustomGrid from '../../../components/CustomGrid';
import ITeamSeason from '../../../dto/interfaces/ITeamSeason';
import api from '../../../api_urls';
import { headerObject } from '../../../state/store/Selectors';
import ITeamOnOffColumns from '../../../dto/interfaces/ITeamOnOffColumns';
import { defaultTeamName } from '../../../state/store/Atoms';
import { TeamOnOffColumnsAG } from '../../../dto/TeamOnOffColumns';
import '../../../css/AGstyles.css';
import { Collapse, FormControlLabel, Switch, Typography } from '@mui/material';
import MultiIntPicker from '../../../components/MultiIntPicker';
import IIntPickerDef from '../../../components/interfaces/IIntPickerDef';

interface IProps {
    team: ITeamSeason
}

export default function OnOff({ team }: IProps) {
    const header = useRecoilValue(headerObject);
    const teamNameIfBlank = useRecoilValue(defaultTeamName);
    const [tableData, setTableData] = useState<ITeamOnOffColumns[]>([]);
    const [fullTableData, setFullTableData] = useState<ITeamOnOffColumns[]>([]);
    const [showFilters, setShowFilters] = useState(false);
    const [minOpp, setMinOpp] = useState<number | string>(0);
    const [minOppO, setMinOppO] = useState<number | string>(0);
    const [minOppD, setMinOppD] = useState<number | string>(0);
    const [minDpp, setMinDpp] = useState<number | string>(0);
    const [minDppO, setMinDppO] = useState<number | string>(0);
    const [minDppD, setMinDppD] = useState<number | string>(0);

    useEffect(() => {
        if (team?.team?.ext_team_id && team?.team?.ext_team_id !== teamNameIfBlank) {
            Promise.all([
                getTeamData().then((data: ITeamOnOffColumns[]) => {
                    setTableData(data);
                    setFullTableData(data);
                })
            ]);
        }
    }, [team])

    async function getTeamData(): Promise<ITeamOnOffColumns[]> {
        const res = await axios.get<ITeamOnOffColumns[]>(`${api.ROOT}${api.TEAMS}/${team.team.ext_team_id}${api.ONOFF}`, header);
        return res.data.filter(x => x.oppA > 0 || x.dppA > 0); // removes blank rows
    }

    const handleShowFilters = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setShowFilters(checked);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        var val = '';
        if (event.target.value !== val) {
            val = event.target.value;
        }
        switch (event.target.id) {
            case "opp":
                setMinOpp(val);
                break;
            case "oppO":
                setMinOppO(val);
                break;
            case "oppD":
                setMinOppD(val);
                break;
            case "dpp":
                setMinDpp(val);
                break;
            case "dppO":
                setMinDppO(val);
                break;
            case "dppD":
                setMinDppD(val);
                break;
        }
    }

    const handleBlur = () => {
        var tableData = fullTableData.filter(x => x.oppA >= minOpp);
        tableData = tableData.filter(x => x.oppO >= minOppO);
        tableData = tableData.filter(x => x.oppD >= minOppD);
        tableData = tableData.filter(x => x.dppA >= minDpp);
        tableData = tableData.filter(x => x.dppO >= minDppO);
        tableData = tableData.filter(x => x.dppD >= minDppD);
        setTableData(tableData);
    }

    const minOppDef: IIntPickerDef = {
        value: minOpp,
        label: "Min opp",
        id: "opp"
    }

    const minOppODef: IIntPickerDef = {
        value: minOppO,
        label: "Min oppO",
        id: "oppO"
    }

    const minOppDDef: IIntPickerDef = {
        value: minOppD,
        label: "Min oppD",
        id: "oppD"
    }

    const minDppDef: IIntPickerDef = {
        value: minDpp,
        label: "Min dpp",
        id: "dpp"
    }

    const minDppODef: IIntPickerDef = {
        value: minDppO,
        label: "Min dppO",
        id: "dppO"
    }

    const minDppDDef: IIntPickerDef = {
        value: minDppD,
        label: "Min dppD",
        id: "dppD"
    }

    const pickerValues: IIntPickerDef[] = [
        minOppDef,
        minOppODef,
        minOppDDef,
        minDppDef,
        minDppODef,
        minDppDDef
    ];

    const isFiltering = () => {
        return !((minOpp === 0) && (minOppO === 0) && (minOppD === 0) && (minDpp === 0) && (minDppO === 0) && (minDppD === 0));
    }

    const reset = () => {
        setMinOpp(0);
        setMinOppO(0);
        setMinOppD(0);
        setMinDpp(0);
        setMinDppO(0);
        setMinDppD(0);
        setTableData(fullTableData);
    }

    return (
        <>
            <div className="flexRow">
                <FormControlLabel control={<Switch checked={showFilters} onChange={handleShowFilters} />} label="Show chart filters" />
                {isFiltering() &&
                    (<>
                        <Typography variant="overline" sx={{ color: "red", cursor: 'pointer' }} onClick={reset}>reset filters</Typography>
                    </>
                    )
                }
            </div>
            <Collapse in={showFilters}>
                <MultiIntPicker values={pickerValues} handleChange={handleChange} handleBlur={handleBlur} marginSize={10} />
            </Collapse>
            <CustomGrid rows={tableData} columns={TeamOnOffColumnsAG} />
        </>
    )
}