import { useMediaQuery } from '@mui/material';
import React from 'react';
import { VictoryChart, VictoryScatter, VictoryLine, VictoryAxis, VictoryVoronoiContainer } from 'victory';
import IFilterablePass from '../../dto/interfaces/IFilterablePass';
import { fieldBordersPortrait as borders, fieldWidth, yMax, borderStyle, majorMarksPortrait, majorStyle, minorMarksPortrait, minorStyle, fieldStyle, xMax, xMin, multiplier } from '../../state/fieldValues';


interface IProps {
    passes: IFilterablePass[];
    display: boolean;
    setActivePassId: (newId: number) => void;
    activePassId: number;
}

interface INode {
    x: number;
    y: number;
    symbol: string;
    fill: string;
    size: number;
    strokeColor: string;
    passId: number;
}

export default function Field({ passes, display, setActivePassId, activePassId }: IProps) {
    const isSmallLaptop = useMediaQuery(`(max-width:1200px)`);
    const goalAssistColor = "rgba(7, 151, 27, 1)";
    const catchColor = "rgba(0,0,0,.5)";
    const noColor = "rgba(0,0,0,0)";
    const turnoverColor = "rgba(255, 0, 0, 1)";
    const callahanColor = "rgba(219, 124, 0, .8)";

    function setFill(datum: IFilterablePass, thrower: boolean = true) {
        switch (datum.result) {
            case "GOAL":
                return thrower ? goalAssistColor : noColor;
            case "CALLAHAN":
                return thrower ? callahanColor : noColor;
            case "THROWING_ERROR":
                return thrower ? turnoverColor : noColor;
            case "DROP":
                return thrower ? catchColor : noColor;
            default:
                return catchColor;
        }
    }

    function setStrokeColor(datum: IFilterablePass, thrower: boolean) {
        switch (datum.result) {
            case "GOAL":
                return thrower ? noColor : goalAssistColor;
            case "CALLAHAN":
                return thrower ? noColor : callahanColor;
            case "THROWING_ERROR" || "DROP":
                return thrower ? noColor : turnoverColor;
            default:
                return noColor;
        }
    }


    function setSize(datum: IFilterablePass): number {
        const screenSizeMultiplier = isSmallLaptop ? 1.5 : 1;
        const active = 3 * screenSizeMultiplier;
        const big = 1.5 * screenSizeMultiplier;
        const small = 1 * screenSizeMultiplier;

        if (datum.passId === activePassId) {
            return active;
        }

        switch (datum.result) {
            case "CATCH":
                return small;
            default:
                return big;
        }
    }

    function setSymbol(datum: IFilterablePass, thrower: boolean = true) {
        const regular = "circle";
        const assist = "diamond";

        switch (datum.result) {
            case "CATCH":
                return regular;
            case "GOAL":
                return thrower ? assist : regular;
            case "THROWING_ERROR":
                return regular;
            case "CALLAHAN":
                return regular;
            default:
                return regular;
        }
    }

    const makeActive = (nowActive: any[]) => {
        if (nowActive?.length > 0) {
            setActivePassId(nowActive[0].passId);
        }
    }

    const makeThrower = (pass: IFilterablePass): INode => {
        const isThrower = true;
        return {
            x: pass.throwerX,
            y: pass.throwerY,
            fill: setFill(pass, isThrower),
            size: setSize(pass),
            symbol: setSymbol(pass, isThrower),
            strokeColor: setStrokeColor(pass, isThrower),
            passId: pass.passId
        } as INode
    }

    const makeTarget = (pass: IFilterablePass): INode => {
        const isThrower = false;
        return {
            x: pass.targetX,
            y: pass.targetY,
            fill: setFill(pass, isThrower),
            size: setSize(pass),
            symbol: setSymbol(pass, isThrower),
            strokeColor: setStrokeColor(pass, isThrower),
            passId: pass.passId
        } as INode
    }

    return (
        <div className="field" >
            <VictoryChart
                domain={{ x: [xMin, xMax], y: [0, yMax] }}
                width={fieldWidth}
                height={yMax}
                padding={0}
                style={fieldStyle}
                containerComponent={
                    <VictoryVoronoiContainer
                        onActivated={(active) => makeActive(active)}
                    />
                }
            >
                <VictoryAxis style={{
                    axis: { stroke: "transparent" },
                    ticks: { stroke: "transparent" },
                    tickLabels: { fill: "transparent" }
                }} />
                <VictoryLine
                    sortKey={"0"}
                    style={borderStyle}
                    data={borders}
                />
                <VictoryLine
                    sortKey={"0"}
                    style={majorStyle}
                    data={majorMarksPortrait}
                />
                <VictoryLine
                    sortKey={"0"}
                    style={minorStyle}
                    data={minorMarksPortrait}
                />

                {display && passes.map((pass) => {
                    const thrower = makeThrower(pass);
                    const target = makeTarget(pass);
                    const passData: INode[] = [thrower, target]
                    return (
                        <VictoryLine
                            data={passData}
                            x={(datum) => datum.x * multiplier}
                            y={(datum) => datum.y * multiplier}
                            sortKey={"0"}
                            style={{
                                data: {
                                    stroke: passData[0].fill,
                                    strokeWidth: ({ active }) => active ? 1.5 : .5
                                },
                            }}
                        />
                    )
                })}

                {display &&
                    <VictoryScatter
                        data={passes}
                        x={(datum) => datum.throwerX * multiplier}
                        y={(datum) => datum.throwerY * multiplier}
                        sortKey={"0"}
                        size={(datum) => setSize(datum.datum)}
                        symbol={(datum) => setSymbol(datum.datum)}
                        style={{
                            data: {
                                fill: ({ datum }) => setFill(datum),
                            }
                        }}
                    />
                }
            </VictoryChart>
        </div >
    )




}