import { useMediaQuery } from '@mui/material';
import React from 'react';
import { VictoryChart, VictoryScatter, VictoryLine, VictoryAxis, VictoryVoronoiContainer } from 'victory';
import IFilterablePass from '../../dto/interfaces/IFilterablePass';
import { fieldBordersPortrait as borders, fieldWidth, yMax, borderStyle, majorMarksPortrait, majorStyle, minorMarksPortrait, minorStyle, fieldStyle, xMax, xMin, multiplier } from '../../state/fieldValues';


interface IProps {
    possessions: { [key: string]: IFilterablePass[] };
    display: boolean;
    setActivePossessionId: (newId: number) => void;
    setActivePassId: (newId: number) => void;
    activePassId: number;
}

export default function Field({ possessions, display, setActivePossessionId, setActivePassId, activePassId }: IProps) {
    const isSmallLaptop = useMediaQuery(`(max-width:1200px)`);

    function isAssist(datum: IFilterablePass): boolean {
        const passId = datum.passId;
        const possessionId = datum.possessionId;

        try {
            const nextPass = possessions[possessionId].find(x => x.passId === passId + 1);
            if (nextPass?.result === "GOAL") {
                return true;
            }
            else {
                return false
            }
        } catch {
            return false;
        }
    }




    function setColor(datum: IFilterablePass) {
        const goalAssistColor = "rgba(7, 151, 27, 1)";
        const catchColor = "rgba(0,0,0,.5)";
        const nullColor = "rgba(0,0,255,1)";
        const turnoverColor = "rgba(255, 0, 0, 1)";
        const injuryColor = "rgba(234, 69, 225, .8)";
        const timeoutColor = "rgba(245, 148, 39, 0.8)"

        if (isAssist(datum) && datum.result !== null) {
            return goalAssistColor;
        }

        if (datum.injury) {
            return injuryColor;
        }
        if (datum.timeout) {
            return timeoutColor;
        }

        switch (datum.result) {
            case "GOAL" || "CALLAHAN":
                return goalAssistColor;
            case "CATCH":
                return catchColor;
            case null:
                return nullColor;
            default:
                return turnoverColor;
        }
    }


    function setSize(datum: IFilterablePass): number {
        const screenSizeMultiplier = isSmallLaptop ? 1.5 : 1;
        const active = 3 * screenSizeMultiplier;
        const injury_timeout = 3 * screenSizeMultiplier;
        const big = 1.5 * screenSizeMultiplier;
        const small = 1 * screenSizeMultiplier;

        if (datum.passId === activePassId) {
            return active;
        }

        if (isAssist(datum)) {
            return big;
        }

        if (datum.injury) {
            return injury_timeout;
        }
        if (datum.timeout) {
            return injury_timeout;
        }
        switch (datum.result) {
            case "CATCH":
                return small;
            default:
                return big;
        }
    }

    function setSymbol(datum: IFilterablePass) {
        const injury = "plus";
        const timeout = "triangleUp";
        const regular = "circle";
        const assist = "diamond";

        if (isAssist(datum)) {
            return assist;
        }

        if (datum.injury) {
            return injury;
        }
        if (datum.timeout) {
            return timeout;
        }

        return regular;
    }

    const makeActive = (nowActive: any[]) => {
        if (nowActive?.length > 0) {
            setActivePossessionId(nowActive[0].possessionId);
            setActivePassId(nowActive[0].passId);
        }
    }

    return (
        <div className="field" >
            <VictoryChart
                domain={{ x: [xMin, xMax], y: [0, yMax] }}
                width={fieldWidth}
                height={yMax}
                padding={0}
                style={fieldStyle}
                containerComponent={
                    <VictoryVoronoiContainer
                        onActivated={(active) => makeActive(active)}
                    />
                }
            >
                <VictoryAxis style={{
                    axis: { stroke: "transparent" },
                    ticks: { stroke: "transparent" },
                    tickLabels: { fill: "transparent" }
                }} />
                <VictoryLine
                    sortKey={"0"}
                    style={borderStyle}
                    data={borders}
                />
                <VictoryLine
                    sortKey={"0"}
                    style={majorStyle}
                    data={majorMarksPortrait}
                />
                <VictoryLine
                    sortKey={"0"}
                    style={minorStyle}
                    data={minorMarksPortrait}
                />



                {display && Object.keys(possessions).map((key) => {
                    return (
                        <VictoryLine
                            data={possessions[key]}
                            x={(datum) => datum.targetX * multiplier}
                            y={(datum) => datum.targetY * multiplier}
                            sortKey={"0"}
                            style={{
                                data: {
                                    stroke: "rgba(0,0,0,1)",
                                    strokeWidth: ({ active }) => active ? 1.5 : .1
                                },
                            }
                            }
                        />
                    )
                })}

                {display && Object.keys(possessions).map((key) => {
                    return (
                        < VictoryScatter
                            data={possessions[key]}
                            x={(datum) => datum.targetX * multiplier}
                            y={(datum) => datum.targetY * multiplier}
                            sortKey={"0"}
                            size={(datum) => setSize(datum.datum)}
                            // size={(x: { datum: any, active: any[] }) => setSize2(x)}
                            symbol={(datum) => setSymbol(datum.datum)}
                            // symbol="circle"
                            style={{
                                data: {
                                    fill: ({ datum }) => setColor(datum),
                                }
                            }}
                        />
                    )
                })}
            </VictoryChart>
        </div >
    )
}